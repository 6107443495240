<template>
    <div>
        <v-card-text class="w-full pt-2 pb-0 white--text self-center justify-center" :class="{'flex': ! isSmall}">
            <slot name="title">
                <v-tooltip top :disabled="hideTitleTooltip">
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="subtitle-1 self-center">
                            <span v-html="usedTitle"/>
                            <span v-if="year && year !== '' && year !== 0 && year !== '0'"> ({{year}})</span>
                        </div>
                    </template>
                    <span>{{ title }}</span>
                </v-tooltip>
            </slot>
            <v-spacer v-if="! isSmall && hasOtherTabs"/>
            <v-btn-toggle
                v-model="activeTab"
                v-if="hasOtherTabs"
                tile
                dense
                color="primary"
                group borderless mandatory
                class="!inline"
            >
                <v-btn :value="'info'" small class=" ml-0 mt-0">
                    {{$t('Media.detailCard.main')}}
                </v-btn>
                <v-btn :value="'cast'" small class=" ml-0 mt-0" v-if="hasCast">
                    {{$t('Media.detailCard.cast')}}
                </v-btn>
                <v-btn :value="'crew'" small class=" ml-0 mt-0" v-if="hasCrew">
                    {{$t('Media.detailCard.crew')}}
                </v-btn>
                <v-btn :value="'companies'" small class=" ml-0 mt-0" v-if="hasCompanies">
                    {{$t('Media.detailCard.companies')}}
                </v-btn>
                <v-btn :value="'additional'" small class=" ml-0 mt-0" v-if="hasAdditional">
                    {{$t('Media.detailCard.additional')}}
                </v-btn>
            </v-btn-toggle>
        </v-card-text>

        <v-flex class="position-relative h-full w-full py-2">
            <div class="h-full self-center px-2" :class="posterClasses" :style="posterStyle" ref="poster" v-if="hasPoster">
                <v-img
                    :src="posterImage"
                    :lazy-src="lazyImage"
                    @load="backgroundLoaded = true"
                    contain
                    :max-height="posterMaxHeight"
                    :class="{grey: ! backgroundLoaded, 'darken-4': ! backgroundLoaded}"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>
            </div>
            <div :class="contentClasses" :style="contentStyle">
                <v-slide-x-transition mode="out-in">
                    <media-detail-card-content-crew
                        key="details-crew"
                        :crew="crew"
                        :has-poster="hasPoster"
                        v-if="activeTab === 'crew' && hasCrew"
                    />
                    <media-detail-card-content-cast
                        key="details-cast"
                        :cast="cast"
                        :has-poster="hasPoster"
                        v-else-if="activeTab === 'cast' && hasCast"
                    />
                    <media-detail-card-content-companies
                        key="details-companies"
                        :companies="companies"
                        :has-poster="hasPoster"
                        v-else-if="activeTab === 'companies' && hasCompanies"
                    />
                    <media-detail-card-content-additional
                        key="details-additional"
                        :has-poster="hasPoster"
                        :api="api"
                        :keywords="keywords"
                        :release="release"
                        :runtime="runtime"
                        :seasons="seasons"
                        :tagline="tagline"
                        :alternative-titles="alternativeTitles"
                        :synonyms="synonyms"
                        :sub-type="subType"
                        :type="type"
                        v-else-if="activeTab === 'additional' && hasAdditional"
                    />
                    <media-detail-card-content-info
                        key="details-info"
                        v-else
                        :small-debug-text="smallDebugText"
                        :small-sub-text="smallSubText"
                        :second-sub-text="secondSubText"
                        :small-second-sub-text="smallSecondSubText"
                        :third-sub-text="thirdSubText"
                        :small-third-sub-text="smallThirdSubText"
                        :sub-text="subText"
                        :right-sub-text="rightSubText"
                        :left-sub-text="leftSubText"
                        :small-desc="smallDesc"
                        :desc="desc"
                        :allow-tooltips="allowTooltips"
                    />
                </v-slide-x-transition>
            </div>
        </v-flex>
    </div>
</template>

<script>
    import MediaDetailCardContentInfo       from './details/MediaDetailCardContentInfo';
    import MediaDetailCardContentAdditional from './details/MediaDetailCardContentAdditional';
    import MediaDetailCardContentCast       from './details/MediaDetailCardContentCast';
    import MediaDetailCardContentCrew       from './details/MediaDetailCardContentCrew';
    import MediaDetailCardContentCompanies  from './details/MediaDetailCardContentCompanies';

    export default {
        components: {
            MediaDetailCardContentInfo,
            MediaDetailCardContentAdditional,
            MediaDetailCardContentCrew,
            MediaDetailCardContentCast,
            MediaDetailCardContentCompanies,
        },

        mounted() {
            this.posterEl = this.$refs.poster;
        },

        props: {
            allowTooltips: {
                type:    Boolean,
                default: true,
            },

            companies: {
                type:    Array,
                default: null,
            },

            cast: {
                type:    Array,
                default: null,
            },

            crew: {
                type:    Array,
                default: null,
            },

            alternativeTitles: {
                type:    Array,
                default: null,
            },

            synonyms: {
                type:    Array,
                default: null,
            },

            keywords: {
                type:    Array,
                default: null,
            },

            api: {
                type:    String,
                default: null,
            },

            type: {
                type:    String,
                default: null,
            },

            runtime: {
                type:    [Number, String],
                default: null,
            },

            seasons: {
                type:    [Number, String],
                default: null,
            },

            tagline: {
                type:    String,
                default: null,
            },

            release: {
                type:    String,
                default: null,
            },

            subType: {
                type:    String,
                default: null,
            },

            title: {
                type:    String,
                default: null,
            },

            smallTitle: {
                type:    String,
                default: null,
            },

            year: {
                type:    [Number, String],
                default: null,
            },

            desc: {
                type:    String,
                default: null,
            },

            smallDesc: {
                type:    String,
                default: null,
            },

            leftSubText: {
                type:    String,
                default: null,
            },

            rightSubText: {
                type:    String,
                default: null,
            },

            smallDebugText: {
                default: null,
            },

            subText: {
                type:    String,
                default: null,
            },

            smallSubText: {
                type:    String,
                default: null,
            },

            secondSubText: {
                type:    String,
                default: null,
            },

            smallSecondSubText: {
                type:    String,
                default: null,
            },

            thirdSubText: {
                type:    String,
                default: null,
            },

            smallThirdSubText: {
                type:    String,
                default: null,
            },

            poster: {
                type:    String,
                default: null,
            },
        },

        data: () => ({
            posterEl:         null,
            activeTab:        'info',
            backgroundLoaded: false,
        }),

        watch: {
            activeTab: function (newValue) {
                if (newValue === '' || ! newValue) {
                    this.$nextTick(() => {
                        this.activeTab = 'info';
                    });
                }
            },
        },

        methods: {},

        computed: {
            hasOtherTabs() {
                return this.hasAdditional || this.hasCast || this.hasCrew || this.hasCompanies;
            },

            hasAdditional() {
                if (this.keywords && Array.isArray(this.keywords) && this.keywords.length > 0) {
                    return true;
                }

                if (this.alternativeTitles && Array.isArray(this.alternativeTitles) && this.alternativeTitles.length > 0) {
                    return true;
                }

                if (this.synonyms && Array.isArray(this.synonyms) && this.synonyms.length > 0) {
                    return true;
                }

                if (this.release && this.release !== '') {
                    try {
                        const date = this.$date(this.release).format(this.$t('dates.date'));

                        if (date) {
                            return true;
                        }
                    } catch (e) {}
                }

                return (this.api && this.api !== '')
                    || (this.type && this.type !== '')
                    || (this.subType && this.subType !== '');

            },

            hasCast() {
                return this.cast && Array.isArray(this.cast) && this.cast.length > 0;
            },

            hasCrew() {
                return this.crew && Array.isArray(this.crew) && this.crew.length > 0;
            },

            hasCompanies() {
                return this.companies && Array.isArray(this.companies) && this.companies.length > 0;
            },

            isSmall() {
                return this.isMobileOrTablet();
            },

            isSmallest() {
                return this.isSmartphonePortrait();
            },

            posterMaxHeight() {
                if (this.isSmartphonePortrait()) {

                }

                if (this.isSmartphoneLandscape()) {

                }

                if (this.isSmallLaptopScreen()) {

                }

                if (! this.isLargeLaptopScreen()) {

                }

                if (this.is4kResolution()) {

                }

                if (this.isXlBreakpoint()) {

                }

                return null;
            },

            posterStyle() {
                if (this.isSmartphonePortrait()) {
                    return 'width: 30%;';
                }

                if (this.isSmartphoneLandscape()) {

                }

                if (this.isSmallLaptopScreen()) {

                }

                if (! this.isLargeLaptopScreen()) {

                }

                if (this.is4kResolution()) {
                    return 'width: 10%;';
                }

                if (this.isXlBreakpoint()) {
                    return 'width: 12%;';
                }

                return null;
            },

            posterClasses() {
                if (this.isSmartphonePortrait()) {
                    return null;
                }

                if (this.isSmartphoneLandscape()) {
                    return 'w-1/4';
                }

                if (this.isSmallLaptopScreen()) {
                    return 'w-1/6';
                }

                if (! this.isLargeLaptopScreen()) {
                    return 'w-1/5';
                }

                if (this.is4kResolution()) {
                    return null;
                }

                if (this.isXlBreakpoint()) {
                    return null;
                }

                return 'w-1/6';
            },

            contentClasses() {
                if (! this.hasPoster) {
                    return 'w-full';
                }

                if (this.isSmartphonePortrait()) {
                    return null;
                }

                if (this.isSmartphoneLandscape()) {
                    return 'w-3/4';
                }

                if (this.isSmallLaptopScreen()) {
                    return 'w-5/6';
                }

                if (! this.isLargeLaptopScreen()) {
                    return 'w-4/5';
                }

                if (this.is4kResolution()) {
                    return null;
                }

                if (this.isXlBreakpoint()) {
                    return null;
                }

                return 'w-5/6';
            },

            contentStyle() {
                if (! this.hasPoster) {
                    return null;
                }

                if (this.isSmartphonePortrait()) {
                    return '70%';
                }

                if (this.isSmartphoneLandscape()) {
                }

                if (this.isSmallLaptopScreen()) {
                }

                if (! this.isLargeLaptopScreen()) {
                }

                if (this.is4kResolution()) {
                    return '90%';
                }

                if (this.isXlBreakpoint()) {
                    return '88%';
                }

                return null;
            },

            hideTooltips() {
                return this.allowTooltips !== false && this.isMobileOrTablet();
            },

            hideSubTextTooltip() {
                if (this.hideTooltips === true) {
                    return true;
                }

                return this.smallSubText === this.subText;
            },

            hideTitleTooltip() {
                if (this.hideTooltips === true || this.smallTitle === '' || this.smallTitle === null) {
                    return true;
                }

                return this.smallTitle === this.title;
            },

            hideDescriptionTooltip() {
                if (this.hideTooltips === true || this.smallDesc === '' || this.smallDesc === null) {
                    return true;
                }

                return this.smallDesc === this.desc;
            },

            usedDesc() {
                if (this.smallDesc && this.smallDesc !== '') {
                    return this.smallDesc;
                }

                return this.desc;
            },

            usedTitle() {
                if (this.smallTitle && this.smallTitle !== '') {
                    return this.smallTitle;
                }

                return this.title;
            },

            lazyImage() {
                if (! this.$vuetify.theme.dark) {
                    return '/img/media/image-light.svg';
                }

                return '/img/media/image-light-grey.svg';
            },

            hasPoster() {
                if (! this.poster || this.poster === '') {
                    return false;
                }

                if (this.isSmall && this.activeTab !== 'info') {
                    return false;
                }

                return true;
            },

            posterImage() {
                if (this.poster && this.poster !== '') {
                    return this.poster;
                }

                return null;
            },

        },
    };
</script>
