<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <text-field-with-validation
                    :rules="nameIsPrimary ? {required: true} : {}"
                    :label="$t('Wishlist.create.form.name')"
                    :hint="$t('Wishlist.create.form.nameHint')"
                    name="name"
                    v-model="innerName"
                    persistent-hint
                    prepend-icon="$formName"/>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <year-picker-with-validation
                    :label="$t('Wishlist.create.form.year')"
                    :hint="$t('Wishlist.create.form.yearHint')"
                    name="year"
                    persistent-hint
                    v-model="innerYear"
                    prepend-icon="$formYear"/>
            </v-col>

            <v-col cols="12" sm="6" md="4">
                <text-field-with-validation
                    :label="$t('Wishlist.create.form.additionalInfos')"
                    :hint="$t('Wishlist.create.form.additionalInfosHint')"
                    name="additionalInfos"
                    persistent-hint
                    v-model="innerAdditionalInfos"
                    prepend-icon=""/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import TextFieldWithValidation from '@symfia/core/components/VeeValidate/TextFieldWithValidation';
    import YearPickerWithValidation from '@symfia/core/components/VeeValidate/YearPickerWithValidation';

    export default {
        components: {
            TextFieldWithValidation,
            YearPickerWithValidation
        },

        props: {
            name: {
                type: String,
                required: true,
            },

            year: {
                type: String,
                required: true,
            },

            additionalInfos: {
                type: String,
                required: true,
            },

            nameIsPrimary: {
                type: Boolean,
                default: true
            },
        },

        watch: {
            name: function (newName, oldValue) {
                if (newName !== oldValue) {
                    this.innerName = newName;
                }
            },

            innerName: function (newName) {
                this.$emit('wishlist-field-change:name', newName);
            },

            year: function (newYear, oldValue) {
                if (newYear !== oldValue) {
                    this.innerYear = newYear;
                }
            },

            innerYear: function (newYear) {
                this.$emit('wishlist-field-change:year', newYear);
            },

            additionalInfos: function (newAdditionalInfos, oldValue) {
                if (newAdditionalInfos !== oldValue) {
                    this.innerAdditionalInfos = newAdditionalInfos;
                }
            },

            innerAdditionalInfos: function (newAdditionalInfos) {
                this.$emit('wishlist-field-change:additionalInfos', newAdditionalInfos);
            }
        },

        mounted() {

        },

        data: () => ({
            innerName: '',
            innerYear: '',
            innerAdditionalInfos: ''
        }),

        created() {
            this.innerYear = this.year;
            this.innerName = this.name;
        },

        methods: {},

        computed: {
        },
    };
</script>
