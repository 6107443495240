<template>
    <ValidationProvider ref="observer" :name="$attrs.translationLabel || $attrs.label" :rules="rules" v-slot="{ errors, valid }" v-bind="$refs" :vid="$attrs.name">
        <v-radio-group
            v-model="innerValue"
            :row="row"
            :label="$attrs.label"
            v-on="$listeners"
            ref="field"
            :id="$attrs.name"
            :error-messages="hasRules ? errors : []"
            :success="hasRules && valid"
            v-bind="$attrs"
        >
            <v-radio v-for="entry in entries" :key="$attrs.label +'-radio-'+entry.value" :label="entry.label" :value="entry.value"></v-radio>
        </v-radio-group>
    </ValidationProvider>
</template>

<script>
    export default {
        props: {
            rules: {
                type: [Object, String],
                default: ""
            },
            row: {
                type: Boolean,
                default: false
            },
            entries: {
                type: Array
            },
            // must be included in props
            value: {
                type: null
            }
        },
        data: () => ({
            innerValue: ""
        }),

        computed: {
            hasRules() {
                if (! this.rules) {
                    return false;
                }

                if (typeof this.rules === 'string' && this.rules === '') {
                    return false;
                }

                if (typeof this.rules !== 'object' || Array.isArray(this.rules)) {
                    return false;
                }

                return Object.keys(this.rules).length >= 1;
            }
        },

        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit("input", newVal);
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            }
        },
        created() {
            if (this.value) {
                this.innerValue = this.value;
            }
        }
    };
</script>
