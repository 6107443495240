var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('switch-with-validation',{attrs:{"label":_vm.$t('Wishlist.create.form.hd'),"hint":_vm.$t('Wishlist.create.form.hdHint'),"persistent-hint":_vm.$vuetify.breakpoint.mdAndUp},model:{value:(_vm.innerHd),callback:function ($$v) {_vm.innerHd=$$v},expression:"innerHd"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('switch-with-validation',{attrs:{"label":_vm.$t('Wishlist.create.form.ov'),"hint":_vm.$t('Wishlist.create.form.ovHint'),"persistent-hint":_vm.$vuetify.breakpoint.mdAndUp},model:{value:(_vm.innerOv),callback:function ($$v) {_vm.innerOv=$$v},expression:"innerOv"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('switch-with-validation',{attrs:{"label":_vm.$t('Wishlist.create.form.priority'),"hint":_vm.$t('Wishlist.create.form.priorityHint'),"persistent-hint":_vm.$vuetify.breakpoint.mdAndUp},model:{value:(_vm.innerPriority),callback:function ($$v) {_vm.innerPriority=$$v},expression:"innerPriority"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('radio-group-with-validation',{attrs:{"row":_vm.$vuetify.breakpoint.mdAndUp,"label":_vm.$t('Wishlist.create.form.type'),"hint":_vm.$t('Wishlist.create.form.typeHint'),"persistent-hint":_vm.$vuetify.breakpoint.mdAndUp,"entries":[
                    {
                        label: _vm.$t('Wishlist.create.form.types.wish'),
                        value: 'wish'
                    },
                    {
                        label: _vm.$t('Wishlist.create.form.types.upgrade'),
                        value: 'upgrade'
                    },
                    {
                        label: _vm.$t('Wishlist.create.form.types.error'),
                        value: 'error'
                    }
                ]},model:{value:(_vm.innerType),callback:function ($$v) {_vm.innerType=$$v},expression:"innerType"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }