var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',_vm._b({ref:"observer",attrs:{"name":_vm.$attrs.translationLabel || _vm.$attrs.label,"rules":_vm.rules,"vid":_vm.$attrs.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [(_vm.$vuetify.breakpoint.smAndDown)?_c('v-dialog',{ref:"dialog",attrs:{"width":_vm.$vuetify.breakpoint.xs ? '90%' : '290px'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.displayValue,"label":_vm.$attrs.translationLabel || _vm.$attrs.label,"prepend-icon":_vm.$attrs.prependIcon,"error-messages":_vm.hasRules ? errors : [],"success":_vm.hasRules && valid,"hint":_vm.$attrs.hint,"persistent-hint":_vm.$attrs['persistent-hint'] !== false,"id":_vm.$attrs.name,"clearable":_vm.$attrs.clearable !== false,"readonly":""},on:{"click:clear":function($event){_vm.innerValue = ''}}},on),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"pickerYearMenu",attrs:{"first-day-of-week":"1","locale":"de","reactive":"","no-title":"","type":"month"},on:{"input":_vm.setYear},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1):_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.displayValue,"label":_vm.$attrs.translationLabel || _vm.$attrs.label,"prepend-icon":_vm.$attrs.prependIcon,"error-messages":_vm.hasRules ? errors : [],"success":_vm.hasRules && valid,"id":_vm.$attrs.name,"hint":_vm.$attrs.hint,"min":_vm.$attrs.min,"max":_vm.$attrs.max,"persistent-hint":_vm.$attrs['persistent-hint'] !== false,"clearable":_vm.$attrs.clearable !== false,"readonly":""},on:{"click:clear":function($event){_vm.innerValue = ''}}},on),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"pickerYearMenu",attrs:{"first-day-of-week":"1","locale":"de","min":_vm.$attrs.min,"max":_vm.$attrs.max,"reactive":"","no-title":"","type":"month"},on:{"input":_vm.setYear},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}},[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)]}}],null,true)},'ValidationProvider',_vm.$refs,false))}
var staticRenderFns = []

export { render, staticRenderFns }