<template>
    <div>
        <template v-if="! emptyResults">
            {{ $t('Media.results.info') }}

            <div class="mt-1">
                {{ $t('Media.results.hideInfo') }}
                <v-btn color="info" x-small text @click="$emit('media-search:result-hidden')">{{ $t('Media.results.hide') }}</v-btn>
            </div>

            <template>
                <div class="mt-4 mb-2" v-html="$t('Media.results.filterInfo')"/>
                <v-row v-if="hasFilter">
                    <v-col cols="12" md="6" lg="4" v-if="hasApiFilter && ! groupedByProviders">
                        <div> {{ $t('Media.results.api') }}</div>
                        <div>
                            <v-btn-toggle
                                v-model="filters.api"
                                tile
                                dense
                                color="primary"
                                group
                            >
                                <v-btn :disabled="! hasSelectableApiFilter" v-for="(api, type) in availableApis" :value="type" :key="type" small>
                                    <template v-if="$vuetify.breakpoint.xs">
                                        {{ api.mobile }}
                                    </template>
                                    <template v-else>
                                        {{ api.title }}
                                    </template>
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-col>

                    <v-col cols="12" md="6" lg="4" v-if="hasTypeFilter && ! isGroupByType">
                        <div> {{ $t('Media.results.type') }}</div>
                        <div>
                            <v-btn-toggle
                                v-model="filters.type"
                                tile
                                dense
                                color="primary"
                                group
                            >
                                <v-btn :disabled="! hasSelectableTypeFilter" v-for="(name, type) in availableTypes" :value="type" :key="type" small>
                                    {{ name }}
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-col>

                    <v-col cols="12" md="6" lg="4" :offset-lg="! hasTypeFilter && ! isGroupByType ? 4 : 0" v-if="hasSubTypeFilter">
                        <div> {{ $t('Media.results.subType') }}</div>
                        <div>
                            <v-btn-toggle
                                v-model="filters.subType"
                                tile
                                dense
                                color="primary"
                                group
                            >
                                <v-btn :disabled="! hasSelectableSubTypeFilter" v-for="(name, type) in availableSubTypes" :value="type" :key="type" small>
                                    {{ name }}
                                </v-btn>
                            </v-btn-toggle>
                        </div>
                    </v-col>
                </v-row>
            </template>

            <template v-if="groupedByProviders">
                <media-search-results-grouped-by-providers
                    :media="filteredSubTypeResults"
                    :providers="availableApis"
                    :types="availableTypes"
                    :grouped-by-type="groupedByType"
                    :name="name"
                    :year="year"
                    :all-results-length="allResultsLength"
                    :force-large="forceLarge"
                    v-on:media-search:result-selected="selectMedia"
                />
            </template>
            <template v-else-if="groupedByType">
                <media-search-results-grouped-by-type
                    :media="filteredSubTypeResults"
                    :providers="availableApis"
                    :types="availableTypes"
                    :name="name"
                    :year="year"
                    :all-results-length="allResultsLength"
                    :force-large="forceLarge"
                    v-on:media-search:result-selected="selectMedia"
                />
            </template>
            <media-search-results-list
                v-else
                :media="filteredSubTypeResults"
                :name="name"
                :year="year"
                :all-results-length="allResultsLength"
                :force-large="forceLarge"
                v-on:media-search:result-selected="selectMedia"
            />
        </template>
        <template v-else>
            {{ $t('Media.results.infoEmpty') }}
        </template>
    </div>
</template>

<script>
    import collect                              from 'collect.js';
    import MediaSearchResultsList               from '@symfia/media/views/MediaSearch/Results/MediaSearchResultsList';
    import MediaSearchResultsGroupedByProviders from '@symfia/media/views/MediaSearch/Results/MediaSearchResultsGroupedByProviders';
    import MediaSearchResultsGroupedByType      from '@symfia/media/views/MediaSearch/Results/MediaSearchResultsGroupedByType';

    import {
        defaultActive as MediaDefaultSearchGroupByProvidersDefault,
        flagName as MediaDefaultSearchGroupByProvidersName,
    } from '../../Config/Flags/MediaDefaultSearchGroupByProviders';

    import {
        defaultActive as MediaDefaultSearchGroupByTypeDefault,
        flagName as MediaDefaultSearchGroupByTypeName,
    } from '../../Config/Flags/MediaDefaultSearchGroupByType';

    export default {
        components: {
            MediaSearchResultsList,
            MediaSearchResultsGroupedByProviders,
            MediaSearchResultsGroupedByType,
        },

        props: {
            media: {
                type:     Object,
                required: true,
            },

            name: {
                type:    String,
                default: null,
            },

            year: {
                type:    [String, Number],
                default: null,
            },

            forceLarge: {
                type:    Boolean,
                default: false,
            },
        },

        data: () => ({
            overrideLarge:          false,
            isGroupByProviders:     null,
            isGroupByType:          null,
            dontHideFilters:        true,
            dontRecalculateFilters: false,
            orderApiFilters:        [
                'themoviedb',
                'anilist',
                'thetvdb',
            ],
            orderTypeFilters:       [
                'movie',
                'show',
            ],
            orderSubTypeFilters:    [
                'normal',
                'animation',
                'anime',
            ],
            filters:                {
                api:     '',
                type:    '',
                subType: '',
            },
        }),

        methods: {
            selectMedia(selected) {
                this.$emit('media-search:result-selected', selected);
            },

            typeFilterExist(type) {
                if (! type || type === '') {
                    return false;
                }

                if (typeof this.availableTypes === 'object' && typeof this.availableTypes[type] !== 'undefined') {
                    return true;
                }

                return false;
            },

            subTypeFilterExist(type) {
                if (! type || type === '') {
                    return false;
                }

                if (typeof this.availableSubTypes === 'object' && typeof this.availableSubTypes[type] !== 'undefined') {
                    return true;
                }

                return false;
            },
        },

        watch: {
            'filters.api': function () {
                if (! this.typeFilterExist(this.filters.type)) {
                    this.filters.type = '';
                }

                if (! this.subTypeFilterExist(this.filters.subType)) {
                    this.filters.subType = '';
                }
            },

            'filters.type': function () {
                if (! this.subTypeFilterExist(this.filters.subType)) {
                    this.filters.subType = '';
                }
            },
        },

        computed: {
            wantsGroupByProvider() {
                return this.isFeatureFlagActive(MediaDefaultSearchGroupByProvidersName, MediaDefaultSearchGroupByProvidersDefault);
            },

            wantsGroupByType() {
                return this.isFeatureFlagActive(MediaDefaultSearchGroupByTypeName, MediaDefaultSearchGroupByTypeDefault);
            },

            groupedByProviders() {
                if (this.isGroupByProviders !== null) {
                    return this.isGroupByProviders;
                }

                return this.wantsGroupByProvider;
            },

            groupedByType() {
                if (this.isGroupByType !== null) {
                    return this.isGroupByType;
                }

                return this.wantsGroupByType;
            },

            emptyResults() {
                return this.allResultsLength < 1;
            },

            wantsLargeNavigation() {
                return this.isFeatureFlagActive(MediaDefaultSearchLargeCardName, MediaDefaultSearchLargeCardDefault);
            },

            hasApiFilter() {
                return Object.keys(this.availableApis).length > 0;
            },

            hasTypeFilter() {
                return Object.keys(this.availableTypes).length > 0;
            },

            hasSelectableApiFilter() {
                return Object.keys(this.availableApis).length > 1;
            },

            hasSelectableTypeFilter() {
                return Object.keys(this.availableTypes).length > 1;
            },

            hasSubTypeFilter() {
                return Object.keys(this.availableSubTypes).length > 0;
            },

            hasSelectableSubTypeFilter() {
                return Object.keys(this.availableSubTypes).length > 1;
            },

            hasFilter() {
                return this.hasApiFilter || this.hasTypeFilter || this.hasSubTypeFilter;
            },

            availableApis() {
                let apis      = {};
                let foundApis = {};

                this.allResults.forEach((item) => {
                    if (typeof apis[item.api] === 'undefined') {
                        foundApis[item.api] = {
                            title:  item.apiTitle,
                            mobile: item.apiSmallTitle,
                        };
                    }
                });

                this.orderApiFilters.forEach((api) => {
                    if (typeof foundApis[api] !== 'undefined') {
                        apis[api] = foundApis[api];
                        delete foundApis[api];
                    }
                });

                apis = {
                    ...apis,
                    ...foundApis,
                };

                this.$logger.debug(
                    'Filters: Apis',
                    apis,
                    'media-search',
                );

                return apis;
            },

            availableTypes() {
                let types = {};
                let FoundTypes = {};

                const filtered = this.dontRecalculateFilters ? this.allResults : this.filteredApiResults;

                filtered.forEach((item) => {
                    if (typeof FoundTypes[item.type] === 'undefined') {
                        FoundTypes[item.type] = item.typeTitle;
                    }
                });

                this.orderTypeFilters.forEach((api) => {
                    if (typeof FoundTypes[api] !== 'undefined') {
                        types[api] = FoundTypes[api];
                        delete FoundTypes[api];
                    }
                });

                types = {
                    ...types,
                    ...FoundTypes,
                };

                this.$logger.debug(
                    'Filters: Types',
                    types,
                    'media-search',
                );

                return types;
            },

            availableSubTypes() {
                let subTypes = {};
                let foundSubTypes = {};

                const filtered = this.dontRecalculateFilters ? this.allResults : this.filteredTypeResults;

                filtered.forEach((item) => {
                    if (typeof foundSubTypes[item.subType] === 'undefined' && item.subType !== null) {
                        foundSubTypes[item.subType] = item.subTypeTitle;
                    }
                });

                this.orderSubTypeFilters.forEach((api) => {
                    if (typeof foundSubTypes[api] !== 'undefined') {
                        subTypes[api] = foundSubTypes[api];
                        delete foundSubTypes[api];
                    }
                });

                subTypes = {
                    ...subTypes,
                    ...foundSubTypes,
                };

                this.$logger.debug(
                    'Filters: SubTypes',
                    subTypes,
                    'media-search',
                );

                return subTypes;
            },

            filteredApiResults() {
                let api     = this.filters.api;
                let results = this.allResults;

                if (api && api !== '') {
                    results = results.filter((result) => {
                        return result.api === api;
                    });
                }

                this.$logger.debug(
                    'Filtered api results',
                    {api, results},
                    'media-search',
                );

                return results;
            },

            filteredTypeResults() {
                let type    = this.filters.type;
                let results = this.filteredApiResults;

                if (type && type !== '') {
                    results = results.filter((result) => {
                        return result.type === type;
                    });
                }

                this.$logger.debug(
                    'Filtered type results',
                    {type, results},
                    'media-search',
                );

                return results;
            },

            filteredSubTypeResults() {
                let subType = this.filters.subType;
                let results = this.filteredTypeResults;

                if (subType && subType !== '') {
                    results = results.filter((result) => {
                        return result.subType === subType;
                    });
                }

                this.$logger.debug(
                    'Filtered sub type results',
                    {subType, results},
                    'media-search',
                );

                return results;
            },

            allResultsLength() {
                return this.allResults.length;
            },

            allResults() {
                let collection = collect(Object.values(this.media));

                //collection = collection.sortByDesc('api').sortByDesc('name').sortByDesc('popularity');

                collection = collection.mapWithKeys(item => [item.typeAndSubtypeWithSlugYear, item]);

                const items = collection.all();

                this.$logger.debug('prepared results', items, 'media-search');

                return Object.values(items);
            },
        },
    };
</script>
