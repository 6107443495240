<template>
    <div>
        <template v-if="allowForcedSearch">
            <div class="mt-4 mb-2">
                <span v-html="$t('Media.results.forceSearch')"/>
                <v-btn
                    color="info"
                    x-small
                    text
                    @click="changeForceSearch">
                    {{ forceFullSearch ? $t('Media.results.forceSearchDeactivate') : $t('Media.results.forceSearchActivate') }}
                </v-btn>
            </div>
        </template>

        <template v-if="! searching && ! hasResults">
            {{ $t('Media.providerInfoText') }}:
            <div v-html="providersText"/>
            <div
                class="mt-2 caption--small !text-smallest leading-none"
                v-html="$t('Media.providerInfoTextHint')"
            />
        </template>
        <template v-else-if="searching && ! displayWhileSearching">
            <div class="flex">
                <v-icon dense left class="flex-column">$spinner</v-icon>

                <div class="flex-column">
                    {{ searchingText }}
                </div>
            </div>
        </template>
        <template v-else>
            <div class="flex" v-if="searching">
                <v-icon dense left class="flex-column">$spinner</v-icon>

                <div class="flex-column">
                    {{ searchingText }}
                </div>
            </div>
            <media-search-results
                v-if="! selected && useDefaultSearchEvent !== false"
                v-on:media-search:result-hidden="hideResults"
                v-on:media-search:result-selected="resultSelected"
                :name="name"
                :year="year"
                :media="filteredMedia"
            />
            <div v-if="selected && useDefaultSelectEvent !== false">
                <div class="flex mb-3" v-if="loadingDetails">
                    <v-icon dense left class="flex-column">$spinner</v-icon>

                    <div class="flex-column">
                        {{$t('Media.selected.loading')}}
                    </div>
                </div>

                <media-search-selected :item="selected" :name="name" :year="year"/>
                <v-btn class="mt-3" small outlined color="error" @click="resetSelected">{{$t('Media.selected.reset')}}</v-btn>
            </div>
        </template>
    </div>
</template>

<script>
    import {mapState}          from 'vuex';
    import {debounce}          from 'lodash';
    import MediaSearchResults  from './MediaSearchResults';
    import MediaSearchSelected from './MediaSearchSelected';

    import {
        defaultActive as MediaDefaultSearchLoadDetailsDefault,
        defaultActiveSettings as MediaDefaultSearchLoadDetailsDefaultSetting,
        flagName as MediaDefaultSearchLoadDetailsName,
        flagNameSettings as MediaDefaultSearchLoadDetailsNameSetting,
    } from '../../Config/Flags/MediaDefaultSearchLoadDetails';

    import {
        defaultActive as MediaDefaultSearchTwoRequestsForLanguageDefault,
        flagName as MediaDefaultSearchTwoRequestsForLanguageName,
    } from '../../Config/Flags/MediaDefaultSearchTwoRequestsForLanguage';

    import {
        flagName as MediaDefaultSearchGroupByProvidersName,
        flagNameSetting as MediaDefaultSearchGroupByProvidersNameSetting,
        defaultActive as MediaDefaultSearchGroupByProvidersDefault,
        defaultActiveSetting as MediaDefaultSearchGroupByProvidersDefaultSetting,
    } from '@symfia/media/Config/Flags/MediaDefaultSearchGroupByProviders';

    export default {
        components: {
            MediaSearchResults,
            MediaSearchSelected,
        },

        props: {
            dontTriggerSearch: {
                type:    Boolean,
                default: false,
            },

            name: {
                required: true,
                default:  null,
            },

            year: {
                default:  null,
            },

            allUrls: {
                type:     Object,
                required: true,
                default:  null,
            },

            completeUrls: {
                type:     Object,
                required: true,
                default:  null,
            },

            types: {
                type:     Object,
                required: true,
                default:  null,
            },

            providers: {
                type:     Object,
                required: true,
                default:  null,
            },

            allProvidersUrl: {
                type:     String,
                required: true,
                default:  null,
            },

            providersData: {
                type:     Object,
                required: true,
                default:  null,
            },

            useCompleteUrls: {
                type:    Boolean,
                default: true,
            },

            useOnlyCompleteSearchUrl: {
                type:    Boolean,
                default: false,
            },

            allowForcedSearch: {
                type:    Boolean,
                default: true,
            },

            forceSearchWithoutDetails: {
                type:    Boolean,
                default: false,
            },

            useDefaultSelectEvent: {
                type:    Boolean,
                default: true,
            },

            useDefaultSearchEvent: {
                type:    Boolean,
                default: true,
            },

            resultFilterList: {
                type:    [Array, Object],
                default: null,
            },
        },

        watch: {
            name() {
                this.handleMediaSearch();
            },

            year() {
                this.mediaSearch();
            },
        },

        beforeMount() {
            this.handleMediaSearch       = debounce(this.mediaSearch, 350);
            this.handleResetSelectedFlag = debounce(this.resetSelectedFlag, 400);
            this.handleSearch            = debounce(this.search, 75);
        },

        beforeDestroy() {
            this.handleMediaSearch.cancel();
            this.handleResetSelectedFlag.cancel();
            this.handleSearch.cancel();
            this.cancelRequests();
        },

        data: () => ({
            axios:                   {},
            axiosDetails:            null,
            loadingDetails:          false,
            handleMediaSearch:       null,
            handleResetSelectedFlag: null,
            selectedReset:           false,
            handleSearch:            null,
            searching:               false,
            hasResults:              false,
            requestsCount:           0,
            requestsLeft:            0,
            selected:                null,
            loadDetails:             null,
            useTwoLanguageCalls:     null,
            forceFullSearch:         false,
            loadedResults:           {
                themoviedb: {},
                other:      {
                    anilist: {},
                    thetvdb: {},
                    unknown: {},
                },
            },
        }),

        methods: {
            changeForceSearch() {
                this.forceFullSearch = ! this.forceFullSearch;

                this.$nextTick(() => {
                    window.setTimeout(() => {
                        this.mediaSearch();
                    }, 100);
                });
            },

            changeLoadDetails() {
                if (this.loadDetails === null) {
                    this.loadDetails = false;
                } else {
                    this.loadDetails = ! this.loadDetails;
                }

                this.mediaSearch();
            },

            changeTwoLanguageCalls() {
                if (this.all === null) {
                    this.useTwoLanguageCalls = false;
                } else {
                    this.useTwoLanguageCalls = ! this.useTwoLanguageCalls;
                }

                this.mediaSearch();
            },

            hideResults() {
                this.resetSearch();
                this.$emit('media-search:result-hidden');
            },

            resultSelected(selectedResult) {
                this.$emit('media-search:result-selected', selectedResult);

                if (this.useDefaultSelectEvent !== false) {
                    this.selected = selectedResult;

                    if (this.forceSearchWithoutDetails !== true) {
                        this.loadDetailsIfNeeded();
                    }
                }

            },

            resetSelected() {
                this.$emit('media-search:selected-result-reset');
                this.cancelLoadDetailsRequest();
                this.selectedReset = true;

                this.$nextTick(() => {
                    this.handleResetSelectedFlag();
                    this.selected = null;
                });
            },

            loadDetailsIfNeeded() {
                if (! this.selected) {
                    this.$logger.debug('Loading Details if needed: No selected Element', null, 'media-search');
                    return;
                }

                if (this.loadingDetails) {
                    this.$logger.debug('Loading Details if needed: Already Loading', null, 'media-search');
                    return;
                }

                if (typeof this.selected.detailsUrl === 'undefined' || this.selected.detailsUrl === '') {
                    this.$logger.debug('Loading Details if needed: No details url', null, 'media-search');
                    return;
                }

                if (typeof this.selected.details === 'object' && this.selected.details !== null) {
                    if (Object.keys(this.selected.details).length > 0) {
                        this.$logger.debug('Loading Details if needed: Details exist', null, 'media-search');
                        return;
                    }
                }

                this.loadingDetails = true;
                this.$logger.debug('Selected result needs to be updated', {
                    result: this.selected,
                }, 'media-search');

                this.$emit('media-search:selected-result-load-details:start');

                this.axiosDetails = this.$axiosToken.source();

                this.$axios.get(
                    this.selected.detailsUrl,
                    {
                        cancelToken: this.axiosDetails.token,
                    },
                ).then((response) => {
                    if (response.data) {
                        this.$logger.debug('Updated selected result', {response}, 'media-search');

                        this.selected = response.data;
                        this.$emit('media-search:update-selected', response.data);
                        this.$emit('media-search:selected-result-load-details:success');
                    }
                }).finally(() => {
                    this.loadingDetails = false;
                    this.axiosDetails   = null;

                    this.$emit('media-search:selected-result-load-details:finish');
                });
            },

            resetSelectedFlag() {
                this.selectedReset = false;
            },

            mediaSearch() {
                this.handleResetSelectedFlag();

                if (this.selected || this.dontTriggerSearch === true || this.selectedReset === true) {
                    return;
                }

                this.handleSearch.cancel();

                if (! this.name || this.name === '') {
                    this.resetSearch();
                } else {
                    this.cancelRequests();
                    this.handleSearch();
                }
            },

            resetLoadedResults() {
                this.loadedResults = {
                    themoviedb: {},
                    other:      {
                        anilist: {},
                        thetvdb: {},
                        unknown: {},
                    },
                };
            },

            search() {
                if (! this.name || this.name === '') {
                    return;
                }

                this.searching  = true;
                this.hasResults = false;
                this.resetLoadedResults();
                this.requestsCount = this.requestsLeft = Object.keys(this.requestUrls).length;

                let data = {
                    name:                       this.name,
                    onlyResults:                1,
                    loadDetails:                this.loadDetailInfos && this.forceSearchWithoutDetails !== true ? 1 : 0,
                    allowMultipleLanguageCalls: this.twoLanguageCalls ? 1 : 0,
                    forceSearch:                this.forceFullSearch ? 1 : 0,
                };

                if (this.year && this.year !== '') {
                    data.year = this.year;
                }

                this.$emit('media-search:start-searching', this.requestsCount);
                let canceled = false;

                for (let mediaSource in this.requestUrls) {
                    const url               = this.requestUrls[mediaSource];
                    this.axios[mediaSource] = this.$axiosToken.source();

                    this.$axios.get(
                        url,
                        {
                            params:      data,
                            cancelToken: this.axios[mediaSource].token,
                        },
                    ).then((response) => {
                        const mediaSourceData = mediaSource.split('-');
                        let mediaType         = null;

                        if (typeof mediaSourceData[1] !== 'undefined') {
                            mediaType = mediaSourceData[0];
                        }

                        if (mediaType &&
                            typeof this.loadedResults[mediaType] !== 'undefined') {
                            this.loadedResults[mediaType] = {
                                ...response.data,
                                ...this.loadedResults[mediaType],
                            };
                        } else if (mediaType &&
                            typeof this.loadedResults['other'][mediaType] !== 'undefined') {
                            this.loadedResults['other'][mediaType] = {
                                ...response.data,
                                ...this.loadedResults['other'][mediaType],
                            };
                        } else {
                            this.loadedResults['other']['unknown'] = {
                                ...response.data,
                                ...this.loadedResults['other']['unknown'],
                            };
                        }

                        this.hasResults = true;

                        this.axios[mediaSource] = null;
                        this.$logger.debug('Loaded Media', {url: url, data: response.data}, 'media-search');

                        this.$emit('media-search:results-received', response.data);
                    }).catch((error) => {
                        if (this.$axiosIsCancel(error)) {
                            canceled                = true;
                            this.hasResults         = false;
                            this.axios[mediaSource] = null;
                            this.$logger.debug('Media Load canceled', {url: url}, 'media-search');
                        } else {
                            this.axios[mediaSource] = null;
                            this.$logger.warning('Media Load error',
                                {
                                    url:       url,
                                    data:      typeof error.response !== 'undefined' && error.response.data !== undefined ? error.response.data : null,
                                    response:  typeof error.response !== 'undefined' ? error.response : null,
                                    exception: error,
                                }, 'media-search');
                        }
                    }).finally(() => {
                        if (this.requestsLeft > 1) {
                            this.requestsLeft--;
                        } else {
                            this.requestsLeft = 0;
                            this.searching    = false;
                            this.$emit('media-search:results-loaded', this.media);
                        }

                        this.$emit('media-search:requests-left', this.requestsLeft);
                        this.$logger.debug('Media Request finished', {requestsLeft: this.requestsLeft}, 'media-search');
                    });
                }
            },

            resetSearch() {
                this.cancelRequests();
                this.searching  = false;
                this.hasResults = false;
                this.resetLoadedResults();
                this.$emit('media-search:results-reset');
            },

            cancelRequests() {
                Object.keys(this.axios).forEach((key) => {
                    if (typeof this.axios[key] !== 'undefined' && this.axios[key] !== null && typeof this.axios[key].cancel === 'function') {
                        try {
                            this.axios[key].cancel();
                            this.axios[key] = null;
                        } catch (e) { }
                    }
                });

                this.cancelLoadDetailsRequest();
            },

            cancelLoadDetailsRequest() {
                if (this.axiosDetails) {
                    this.axiosDetails.cancel();
                    this.axiosDetails = null;
                }
            },
        },

        computed: {
            ...mapState('Configuration', ['configs']),

            displayWhileSearching() {
                if (! this.hasResults || ! this.searching) {
                    return false;
                }

                return this.wantsInstantResultsIfGroupedByProvider;
            },

            wantsInstantResultsIfGroupedByProvider() {
                const active = this.isFeatureFlagActive(MediaDefaultSearchGroupByProvidersName, MediaDefaultSearchGroupByProvidersDefault);

                if (! active) {
                    return false;
                }

                return this.getFeatureFlagSetting(
                    MediaDefaultSearchGroupByProvidersName,
                    MediaDefaultSearchGroupByProvidersNameSetting,
                    MediaDefaultSearchGroupByProvidersDefaultSetting,
                );
            },

            loadDetailInfos() {
                if (this.loadDetails !== null) {
                    return this.loadDetails;
                }

                return this.wantsMoreInformation;
            },

            twoLanguageCalls() {
                if (this.useTwoLanguageCalls !== null) {
                    return this.useTwoLanguageCalls;
                }

                return this.allowsTwoRequestsForLanguage;
            },

            wantsMoreInformation() {
                if (this.isMobileOrTablet()) {
                    return this.getFeatureFlagSetting(
                        MediaDefaultSearchLoadDetailsName,
                        MediaDefaultSearchLoadDetailsNameSetting,
                        MediaDefaultSearchLoadDetailsDefaultSetting,
                    );
                }

                return this.isFeatureFlagActive(MediaDefaultSearchLoadDetailsName, MediaDefaultSearchLoadDetailsDefault);
            },

            allowsTwoRequestsForLanguage() {
                return this.isFeatureFlagActive(MediaDefaultSearchTwoRequestsForLanguageName, MediaDefaultSearchTwoRequestsForLanguageDefault);
            },

            media() {
                let results = {};

                const loadedResults = Object.keys(this.loadedResults);

                loadedResults.forEach((resultName) => {
                    if (resultName !== 'other') {
                        let resultData = typeof this.loadedResults[resultName] === 'object' ? this.loadedResults[resultName] : {};

                        this.$logger.debug('Load results for type', {
                            type:       resultName,
                            resultData: resultData,
                        }, 'media-search');

                        results = {
                            ...resultData,
                            ...results,
                        };
                    }
                });

                if (typeof this.loadedResults['other'] === 'object') {
                    Object.keys(this.loadedResults['other']).forEach((resultName) => {
                        let resultData = typeof this.loadedResults['other'][resultName] === 'object' ? this.loadedResults['other'][resultName] : {};

                        this.$logger.debug('Load results for other type', {
                            type:       resultName,
                            resultData: resultData,
                        }, 'media-search');

                        results = {
                            ...resultData,
                            ...results,
                        };
                    });
                }

                this.$logger.debug('Loaded and combined Results from providers', {
                    results: results,
                    source:  this.loadedResults,
                }, 'media-search');

                return results;
            },

            searchingText() {
                if (! this.searching) {
                    return '';
                }

                if (this.requestsLeft < this.requestsCount) {
                    if (this.requestsLeft === 1) {
                        return this.$t('Media.searching.fewWithCountOne');
                    }

                    return this.$t('Media.searching.fewWithCount', {count: this.requestsLeft});
                }

                return this.$t('Media.searching.all');
            },

            frontendConfig() {
                if (typeof this.configs === 'object' && typeof this.configs.wishlist === 'object' && typeof this.configs.wishlist.frontend === 'object') {
                    return this.configs.wishlist.frontend;
                }

                return {};
            },

            requestUrls() {
                if (typeof this.frontendConfig.multipleRequests !== 'undefined' && this.frontendConfig.multipleRequests === false) {
                    if (! this.allProvidersUrl || this.allProvidersUrl === '') {
                        return {};
                    }

                    return {
                        all: this.allProvidersUrl,
                    };
                }

                if (this.useCompleteUrls === true && typeof this.completeUrls === 'object') {
                    return this.completeUrls;
                }

                return typeof this.allUrls === 'object' ? this.allUrls : {};
            },

            providersText() {
                let text  = '';
                let first = true;

                Object.values(this.providersData).forEach((provider) => {
                    if (first !== true) {
                        text += '<div class="mt-2"></div>';
                    }

                    const provides = typeof provider.provides === 'object' ? Object.keys(provider.provides) : [];

                    if (provides.length > 0) {
                        let classes = '';

                        if (! provider.featureFlagSettings.active) {
                            classes += 'line-through';
                        }

                        text += '<span class="' + classes + '">' + provider.label;

                        text += ' (';

                        let firstMedia = true;

                        provides.forEach((key) => {
                            const media = provider.provides[key];

                            if (firstMedia !== true) {
                                text += ', ';
                            }

                            let mediaClasses = '';

                            if (provider.featureFlagSettings.active) {
                                if (typeof provider.featureFlagSettings.media === 'object'
                                    && typeof provider.featureFlagSettings.media[key] !== 'undefined'
                                    && ! provider.featureFlagSettings.media[key]
                                ) {
                                    mediaClasses += 'line-through';
                                }
                            }

                            text += '<span class="' + mediaClasses + '">' + media + '</span>';

                            firstMedia = false;
                        });

                        text += ')</span>';

                        first = false;

                        const providesSubTypes = typeof provider.providesSubTypes === 'object' ? Object.values(provider.providesSubTypes) : [];

                        if (providesSubTypes.length > 0) {
                            text += ' <div class="caption">' + this.$t('Media.providesSubTypeHint') + ': ';

                            let firstSubType = true;

                            providesSubTypes.forEach((subType) => {
                                if (firstSubType !== true) {
                                    text += ', ';
                                }

                                text += subType;

                                firstSubType = false;
                            });

                            text += '</div>';
                        }

                        if (provider.featureFlagSettings.active && ! provider.featureFlagSettings.loadDetails) {
                            text += ' <div class="caption">' + this.$t('Media.providerLoadSNoDetails') + '</div>';
                        }

                    }
                });

                return text;
            },

            emptyResults() {
                if (! this.hasResults) {
                    return false;
                }

                return Object.keys(this.filteredMedia).length < 1;
            },

            filteredMedia() {
                if (! this.hasResults) {
                    return [];
                }

                let results = this.media;

                if (this.resultFilterList && Array.isArray(this.resultFilterList)) {
                    Object.keys(results).forEach((loadedResultSlug) => {
                        const slug     = results[loadedResultSlug].typeWithSlugYear || null;
                        const included = this.resultFilterList.includes(slug);

                        if (included) {
                            delete results[loadedResultSlug];
                        }
                    });
                }

                return results;
            }
        },
    };
</script>
