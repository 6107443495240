<template>
    <v-row :class="{'pl-4': ! hasPoster}">
        <v-col
            cols="6"
            sm="4"
            md="4"
            lg="3"
            xl="2"
            v-for="crewMember in crew"
            :key="crewMember.name +'-' + crewMember.job"
        >
            <v-tooltip
                v-if="crewMember.poster && crewMember.poster !== ''"
                left
                allow-overflow
                open-delay="300"
                :disabled="isMobile"
            >
                <template v-slot:activator="{ on }">
                    <v-avatar
                        v-on="on"
                        :size="72"
                    >
                        <v-img :src="crewMember.poster" position="0% 35%"/>
                    </v-avatar>

                    <br/>
                </template>
                <span>
                    <v-img :src="crewMember.poster" position="top" max-width="200px"/>
                </span>
            </v-tooltip>
            <div>
                {{crewMember.name}}
                <br v-if="getJobAndDepartment(crewMember)"> {{getJobAndDepartment(crewMember)}}
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            hasPoster: {
                type: Boolean,
                default: true
            },

            crew: {
                type:    Array,
                default: null,
            },
        },

        methods: {
            getJobAndDepartment(crewMember) {
                let jobAndDepartment = null;

                if (crewMember.job && crewMember.job !== '') {
                    jobAndDepartment = crewMember.job;
                }

                if (crewMember.department && crewMember.department !== '') {
                    if (jobAndDepartment && jobAndDepartment !== '') {
                        jobAndDepartment += ' (' + crewMember.department + ')';
                    } else {
                        jobAndDepartment = crewMember.department;
                    }
                }

                return jobAndDepartment;
            },
        },

        computed: {
            isMobile() {
                return this.isMobileOrTablet();
            },
        },
    };
</script>
