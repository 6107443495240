<template>
    <div>
        <ValidationObserver v-slot="{ handleSubmit, invalid, failed }" ref="form" slim>
            <v-card flat class="!bg-transparent" :class="formShadow">
                <v-form ref="form" @submit.prevent="handleSubmit(save)" @keyup.native.enter="handleSubmit(save)">
                    <template v-if="! stepBased">
                        <v-card class="!bg-transparent p-3" flat>
                            <v-card class="!bg-transparent" flat>
                                <v-card-subtitle class="!mt-0 !pt-0" v-html="$t('Wishlist.create.steps.main')"/>
                                <v-divider/>
                                <v-card-text>
                                    <main-fields
                                        :name="form.name" v-on:wishlist-field-change:name="form.name = $event"
                                        :year="form.year" v-on:wishlist-field-change:year="form.year = $event"
                                        :additional-infos="form.additionalInfos" v-on:wishlist-field-change:additionalInfos="form.additionalInfos = $event"
                                        :name-is-primary="form.apiInfos === null || form.apiInfos.name || form.apiInfos.name === ''"
                                    />
                                </v-card-text>
                            </v-card>

                            <v-card class="!bg-transparent" flat>
                                <v-card-subtitle class="!mt-0 !pt-0" v-html="$t('Wishlist.create.steps.info')"/>
                                <v-divider/>
                                <v-card-text>
                                    <info-fields
                                        :note="form.note" v-on:wishlist-field-change:note="form.note = $event"
                                        :release="form.release" v-on:wishlist-field-change:release="form.release = $event"
                                    />
                                </v-card-text>
                            </v-card>

                            <v-card class="!bg-transparent" flat>
                                <v-card-subtitle class="!mt-0 !pt-0" v-html="$t('Wishlist.create.steps.category')"/>
                                <v-divider/>
                                <v-card-text>
                                    <category-fields
                                        :type="form.type" v-on:wishlist-field-change:type="form.type = $event"
                                        :hd="form.hd" v-on:wishlist-field-change:hd="form.hd = $event"
                                        :priority="form.priority" v-on:wishlist-field-change:priority="form.priority = $event"
                                        :ov="form.ov" v-on:wishlist-field-change:ov="form.ov = $event"
                                    />
                                </v-card-text>
                            </v-card>
                        </v-card>

                    </template>
                    <template v-else>
                        <v-stepper v-model="step" class="!bg-transparent !shadow-none">
                            <v-stepper-header>
                                <v-stepper-step :rules="failed ? [() => false] : []" editable :step="1">{{$t('Wishlist.create.steps.main')}}</v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step editable :step="2">{{$t('Wishlist.create.steps.info')}}</v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step editable :step="3">{{$t('Wishlist.create.steps.category')}}</v-stepper-step>
                            </v-stepper-header>

                            <v-stepper-items>
                                <v-stepper-content :step="1">
                                    <main-fields
                                        :name="form.name" v-on:wishlist-field-change:name="form.name = $event"
                                        :year="form.year" v-on:wishlist-field-change:year="form.year = $event"
                                        :additional-infos="form.additionalInfos" v-on:wishlist-field-change:additionalInfos="form.additionalInfos = $event"
                                        :name-is-primary="needsNameValidation"
                                    />

                                    <v-flex class="mt-1" v-if="stepShowNavigation">
                                        <v-spacer/>
                                        <v-btn
                                            color="primary"
                                            outlined
                                            small
                                            @click="step = 2"
                                        >
                                            {{ nextStepTitle }}
                                        </v-btn>
                                    </v-flex>
                                </v-stepper-content>

                                <v-stepper-content :step="2">
                                    <info-fields
                                        :note="form.note" v-on:wishlist-field-change:note="form.note = $event"
                                        :release="form.release" v-on:wishlist-field-change:release="form.release = $event"
                                    />

                                    <v-flex class="mt-1" v-if="stepShowNavigation">
                                        <v-btn
                                            color="primary"
                                            outlined
                                            small
                                            @click="step = 1"
                                        >
                                            {{ previousStepTitle }}
                                        </v-btn>
                                        <v-spacer/>
                                        <v-btn
                                            color="primary"
                                            outlined
                                            small
                                            @click="step = 3"
                                        >
                                            {{ nextStepTitle }}
                                        </v-btn>
                                    </v-flex>
                                </v-stepper-content>

                                <v-stepper-content :step="3">
                                    <category-fields
                                        :type="form.type" v-on:wishlist-field-change:type="form.type = $event"
                                        :hd="form.hd" v-on:wishlist-field-change:hd="form.hd = $event"
                                        :priority="form.priority" v-on:wishlist-field-change:priority="form.priority = $event"
                                        :ov="form.ov" v-on:wishlist-field-change:ov="form.ov = $event"
                                    />

                                    <v-flex class="mt-1" v-if="stepShowNavigation">
                                        <v-btn
                                            color="primary"
                                            outlined
                                            small
                                            @click="step = 2"
                                        >
                                            {{ previousStepTitle }}
                                        </v-btn>
                                    </v-flex>
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </template>

                    <v-alert
                        class="mt-2"
                        type="error"
                        :value="alert.visible"
                        :dismissible="true"
                        transition="fade-transition"
                    >
                        <nl2br tag="span" :text="alert.text"/>
                    </v-alert>
                </v-form>

                <sticky-with-intersection :background-sticky-color-classes="stickyColorClasses">
                    <v-card-text class="flex">
                        <v-btn color="secondary" @click="redirectToList" small>{{$t('Wishlist.create.cancel')}}</v-btn>
                        <v-spacer/>
                        <v-tooltip top :disabled="$vuetify.breakpoint.smAndDown || hasApi">
                            <template v-slot:activator="{ on }">
                                <v-btn color="success" v-on="on" small :disabled="invalid" :loading="saving" @click="save">
                                    {{ $t('Wishlist.create.form.save') }}
                                </v-btn>
                            </template>
                            <span>{{ $t('Wishlist.create.form.saveWithoutApi') }}</span>
                        </v-tooltip>
                    </v-card-text>
                </sticky-with-intersection>

                <v-card-subtitle class="cursor-pointer" v-if="changesFromApi.name && originals.name !== ''" @click="resetNameToInput">
                    {{ $t('Wishlist.create.media.nameBackToInput', {name: originals.name}) }}
                </v-card-subtitle>

                <media-search-core
                    ref="searchCore"
                    :year="form.year"
                    :name="form.name"
                    :dont-trigger-search="dontTriggerSearch"
                    v-on:media-search:result-selected="resultSelected"
                    v-on:media-search:selected-result-reset="resetResult"
                    v-on:media-search:update-selected="updateSelectedResult"
                />
            </v-card>
        </ValidationObserver>
    </div>
</template>

<script>
    import {
        flagName,
        defaultActive,
        stepsOnMobileSettingName,
        stepsOnMobileSettingDefault,
        nextPreviousButtonSettingName,
        nextPreviousButtonSettingDefault,
        nextPreviousButtonOnMobileSettingDefault,
        nextPreviousButtonOnMobileSettingName,
    }                      from '../Config/Flags/CreateStepBasedFlag';
    import MainFields      from './Helper/Create/MainFields';
    import InfoFields      from './Helper/Create/InfoFields';
    import CategoryFields  from './Helper/Create/CategoryFields';
    import MediaSearchCore from '@symfia/media/views/MediaSearch/MediaSearchCore';
    import StickyWithIntersection from '@symfia/library/Views/Helper/StickyWithIntersection';

    export default {
        components: {
            MainFields,
            InfoFields,
            CategoryFields,
            MediaSearchCore,
            StickyWithIntersection
        },

        props: {
            defaultUseSteps: {
                type:    Boolean,
                default: null,
            },
            noShadow:      {
                type:    Boolean,
                default: false,
            },
            noRedirect:      {
                type:    Boolean,
                default: false,
            },
            redirectToWish:  {
                type:    Boolean,
                default: false,
            },
            comesFromRoute:  {
                type:    String,
                default: '/wishes/open',
            },
        },

        watch: {
            'form.year'(newVal, oldVal) {
                if (this.isChangingFromApi === false && newVal !== oldVal) {
                    this.changesFromApi.year = false;
                }
            },

            'form.release'(newVal, oldVal) {
                if (this.isChangingFromApi === false && newVal !== oldVal) {
                    this.changesFromApi.release = false;
                }
            },

            'form.name'(newVal, oldVal) {
                if (this.isChangingFromApi === false && newVal !== oldVal) {
                    this.changesFromApi.name = false;
                }
            },
        },

        data: () => ({
            useSteps:          null,
            step:              1,
            saving:            false,
            dontTriggerSearch: false,
            hasApi:            false,
            isChangingFromApi: false,
            changesFromApi:    {
                year:    false,
                release: false,
                name:    false,
            },
            alert:             {
                text:    '',
                visible: false,
            },
            originals:         {
                name:    '',
                year:    '',
                release: '',
            },
            form:              {
                name:             '',
                year:             '',
                additionalInfos:  '',
                release:          '',
                note:             '',
                type:             'wish',
                hd:               false,
                ov:               false,
                priority:         false,
                apiInfos:         null,
                apiDetailInfos:   null,
                apiOriginalInfos: null,
            },
        }),

        methods: {
            resultSelected(selectedResult) {
                this.originals.name = this.form.name;
                this.originals.yeae = this.form.year;

                this.updateSelectedResult(selectedResult);
            },

            updateSelectedResult(selectedResult) {
                let apiInfos      = {...selectedResult};
                let detailInfos   = null;
                let originalInfos = null;

                if (typeof apiInfos.details !== 'undefined') {
                    detailInfos = apiInfos.details;
                    delete apiInfos.details;
                }

                if (typeof apiInfos.apiData !== 'undefined') {
                    originalInfos = apiInfos.apiData;
                    delete apiInfos.apiData;
                }

                this.form.apiInfos         = apiInfos;
                this.form.apiDetailInfos   = detailInfos;
                this.form.apiOriginalInfos = originalInfos;
                this.hasApi                = true;
                this.isChangingFromApi     = true;

                this.$nextTick(() => {
                    if ((this.form.year === '' || ! this.form.year) && typeof apiInfos.year !== 'undefined' && apiInfos.year > 0) {
                        this.originals.year      = this.form.year;
                        this.changesFromApi.year = true;
                        this.form.year           = apiInfos.year.toString();
                    }

                    if (typeof apiInfos.name !== 'undefined' && this.form.name !== apiInfos.name) {
                        this.originals.name      = this.form.name;
                        this.changesFromApi.name = true;
                        this.form.name           = apiInfos.name;
                    }

                    if ((this.form.release === '' || ! this.form.release)
                        && typeof apiInfos.release !== 'undefined'
                        && apiInfos.release !== ''
                        && apiInfos.release !== null
                    ) {
                        let parsedRelease = null;
                        try {
                            parsedRelease = this.$date(apiInfos.release);
                        } catch (e) {}

                        if (parsedRelease && parsedRelease.isAfter(this.$date())) {
                            this.originals.release      = this.form.release;
                            this.changesFromApi.release = true;
                            this.form.release           = apiInfos.release;
                        }
                    }

                    this.$nextTick(() => {
                        this.isChangingFromApi = false;
                    });
                });
            },

            resetNameToInput() {
                if (this.changesFromApi.name && this.originals.name && this.originals.name !== '') {
                    this.form.name           = this.originals.name;
                    this.changesFromApi.name = false;
                }
            },

            resetResult() {
                this.dontTriggerSearch = true;

                this.$nextTick(() => {
                    if (this.changesFromApi.name) {
                        this.form.name = this.originals.name;
                    }

                    if (this.changesFromApi.release) {
                        this.form.release = this.originals.release;
                    }

                    if (this.changesFromApi.year) {
                        this.form.year = this.originals.year;
                    }

                    this.form.apiInfos         = null;
                    this.form.apiDetailInfos   = null;
                    this.form.apiOriginalInfos = null;

                    this.originals.name    = '';
                    this.originals.year    = '';
                    this.originals.release = '';
                    this.hasApi            = false;

                    this.changesFromApi.name    = false;
                    this.changesFromApi.year    = false;
                    this.changesFromApi.release = false;

                    this.$nextTick(() => {
                        this.dontTriggerSearch = false;
                    });
                });
            },

            changeStepStatus() {
                if (this.useSteps === null) {
                    this.useSteps = true;
                } else {
                    this.useSteps = ! this.useSteps;
                }
            },

            save() {
                this.alert.visible = false;

                if (this.saving) {
                    return;
                }

                this.saving = true;
                this.$store.dispatch('activateLoader');

                let data = this.form;

                if (data.name === '' && data.apiInfos && data.apiInfos.name) {
                    data.name = data.apiInfos;
                }

                this.$axios.post(this.route('pwatcher.wishlist.create'), data).then((response) => {
                    if (this.noRedirect !== true) {
                        if (! this.redirectToWish) {
                            this.redirectToList();
                        } else {
                            this.$router.push('/wish/' + response.data.id);
                        }
                    } else {
                        this.$emit('pwatcher:wishlist:wish-created', response.data);

                        if (typeof this.$refs.searchCore !== 'undefined' && typeof this.$refs.searchCore.triggerReset === 'object') {
                            this.$refs.searchCore.triggerReset();
                        }
                    }
                }).catch((error) => {
                    const responseData = this.handleAxiosGlobalResponseErrors(error);

                    if (responseData.responseAvailable
                        && typeof responseData.data !== 'undefined'
                        && typeof responseData.data.message !== 'undefined'
                        && responseData.data.message !== '') {
                        this.alert.text    = responseData.data.message;
                        this.alert.visible = true;
                    } else {
                        this.$logger.error('Wish Create Error', [
                            error.response,
                            error.request,
                            error.message,
                        ], 'wishlist');

                        this.$notifications.notify({
                            messageKey: 'Wishlist.create.notifications.error',
                            type:       'error',
                        }, this);
                    }
                }).finally(() => {
                    this.saving = false;
                    this.$store.dispatch('deactivateLoader');
                });
            },

            redirectToList() {
                if (this.noRedirect !== true) {
                    let route = this.comesFromRoute && this.comesFromRoute !== '' ? this.comesFromRoute : '/wishes/open';

                    if (this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
                        route = this.$route.query.redirect;
                    }

                    this.$router.push(route);
                } else {
                    this.$emit('pwatcher:wishlist:create-cancel');

                    if (typeof this.$refs.searchCore !== 'undefined' && typeof this.$refs.searchCore.triggerReset === 'object') {
                        this.$refs.searchCore.triggerReset();
                    }
                }
            },
        },

        computed: {
            stickyColorClasses() {
                return this.$store.getters['ColorManager/getSystemAppBarColorForOtherElements'];
            },

            needsNameValidation() {
                if (this.form.apiInfos && typeof this.form.apiInfos.name !== 'undefined' && this.form.apiInfos.name !== '') {
                    return false;
                }

                return true;
            },

            formShadow() {
                return this.noShadow === true ? null : this.highlightedBackgroundShadow(true);
            },

            previousStepTitle() {
                if (this.isMobileOrTablet()) {
                    return this.$t('Wishlist.create.previousStepMobile');
                }

                return this.$t('Wishlist.create.previousStep');
            },

            nextStepTitle() {
                if (this.isMobileOrTablet()) {
                    return this.$t('Wishlist.create.nextStepMobile');
                }

                return this.$t('Wishlist.create.nextStep');
            },

            stepBased() {
                if (this.useSteps !== null) {
                    return this.useSteps;
                }

                if (this.defaultUseSteps !== null) {
                    return this.defaultUseSteps;
                }

                if (this.isFeatureFlagActive(flagName, defaultActive) === true) {
                    if (this.isMobileOrTablet() && this.getFeatureFlagSetting(flagName, stepsOnMobileSettingName, stepsOnMobileSettingDefault) === false) {
                        return false;
                    }

                    return true;
                }

                return this.isFeatureFlagActive(flagName, defaultActive);
            },

            stepShowNavigation() {
                if (this.getFeatureFlagSetting(flagName, nextPreviousButtonSettingName, nextPreviousButtonSettingDefault) !== true) {
                    return false;
                }

                if (this.isMobileOrTablet()
                    && this.getFeatureFlagSetting(flagName, nextPreviousButtonOnMobileSettingName, nextPreviousButtonOnMobileSettingDefault) === false
                ) {
                    return false;
                }

                return true;
            },
        },
    };
</script>
