<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6">
                <date-picker-with-validation
                    :label="$t('Wishlist.create.form.release')"
                    :hint="$t('Wishlist.create.form.releaseHint')"
                    v-model="innerRelease"
                    :persistent-hint="$vuetify.breakpoint.mdAndUp"
                    no-title
                    :min="minDate"
                    prepend-icon="$formYear"/>
            </v-col>

            <v-col cols="12" sm="6">
                <text-area-with-validation
                    :label="$t('Wishlist.create.form.note')"
                    :hint="$t('Wishlist.create.form.noteHint')"
                    note="note"
                    rows="1"
                    :persistent-hint="$vuetify.breakpoint.mdAndUp"
                    v-model="innerNote"
                    auto-grow
                    prepend-icon=""/>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import TextAreaWithValidation   from '@symfia/core/components/VeeValidate/TextAreaWithValidation';
    import DatePickerWithValidation from '@symfia/core/components/VeeValidate/DatePickerWithValidation';

    export default {
        components: {
            TextAreaWithValidation,
            DatePickerWithValidation,
        },

        props: {
            release: {
                type:     String,
                required: true,
            },

            note: {
                type:     String,
                required: true,
            },
        },

        watch: {
            note: function (newNote, oldValue) {
                if (newNote !== oldValue) {
                    this.innerNote = newNote;
                }
            },

            innerNote: function (newNote) {
                this.$emit('wishlist-field-change:note', newNote);
            },

            release: function (newRelease, oldValue) {
                if (newRelease !== oldValue) {
                    this.innerRelease = newRelease;
                }
            },

            innerRelease: function (newRelease) {
                this.$emit('wishlist-field-change:release', newRelease);
            },
        },

        mounted() {

        },

        data: () => ({
            innerNote:    '',
            innerRelease: '',
            menu:         false,
        }),

        methods: {},

        created() {
            this.innerNote    = this.note;
            this.innerRelease = this.release;
        },

        computed: {
            minDate() {
                return this.$date().add(1, 'day').format(this.$t('dates.database_date'));
            }
        },
    };
</script>
