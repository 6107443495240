<template>
    <v-card-text>
        <v-card-subtitle v-if="loading">
            <div class="flex">
                <v-icon class="flex-column" left dense v-html="'$spinner'"/>
                <div class="flex-column">
                    {{ $t('Media.loadingProviders') }}
                </div>
            </div>
        </v-card-subtitle>
        <template v-else>
            <media-search-providers
                ref="providers"
                v-on:media-search:result-selected="resultSelected"
                v-on:media-search:selected-result-reset="resetResult"
                v-on:media-search:update-selected="updateSelectedResult"
                v-on:media-search:selected-result-load-details:start="$emit('media-search:selected-result-load-details:start')"
                v-on:media-search:selected-result-load-details:success="$emit('media-search:selected-result-load-details:success')"
                v-on:media-search:selected-result-load-details:finish="$emit('media-search:selected-result-load-details:finish')"
                :dont-trigger-search="dontTriggerSearch"
                :year="year"
                :name="name"
                :all-providers-url="mediaProviders.searchAllProvidersUrl"
                :all-urls="mediaProviders.typedUrls"
                :complete-urls="mediaProviders.completeUrls"
                :providers="mediaProviders.providers"
                :providers-data="mediaProviders.providersData"
                :types="mediaProviders.types"
                :allow-forced-search="allowForcedSearch"
                :force-search-without-details="forceSearchWithoutDetails"
                :use-default-search-event="useDefaultSearchEvent"
                :use-default-select-event="useDefaultSelectEvent"
                :result-filter-list="resultFilterList"
            />
        </template>
    </v-card-text>
</template>

<script>
    import MediaSearchProviders from './MediaSearchProviders';

    export default {
        components: {
            MediaSearchProviders,
        },

        props: {
            name: {
                type:     String,
                required: true,
                default:  null,
            },

            dontTriggerSearch: {
                type:     Boolean,
                default:  false,
            },

            forceSearchWithoutDetails: {
                type:     Boolean,
                default:  false,
            },

            useDefaultSelectEvent: {
                type:     Boolean,
                default:  true,
            },

            useDefaultSearchEvent: {
                type:     Boolean,
                default:  true,
            },

            year: {
                type:     [String, Number],
                required: true,
                default:  null,
            },

            resultFilterList: {
                type:     [Array, Object],
                default:  null,
            },

            allowForcedSearch: {
                type: Boolean,
                default: true
            },
        },

        beforeMount() {
            this.loadMediaProviders();
        },

        beforeDestroy() {
            this.cancelRequests();
        },

        data: () => ({
            axios:          null,
            mediaProviders: {
                providersData:              {},
                providersDataWithoutAllUrl: {},
                typedUrls:                  {},
                showUrls:                   {},
                movieUrls:                  {},
                types:                      {},
                subTypes:                   {},
                providers:                  {},
                searchAllProvidersUrl:      null,
            },
            loading:        false,
        }),

        methods: {
            resultSelected(selectedResult) {
                this.$emit('media-search:result-selected', selectedResult);
            },

            updateSelectedResult(result) {
                this.$emit('media-search:update-selected', result);
            },

            resetResult() {
                this.$emit('media-search:selected-result-reset');
            },

            triggerReset() {
                this.$refs.providers.resetSelected();
            },

            cancelRequests() {
                if (this.axios) {
                    this.axios.cancel();
                }
            },

            triggerSearch() {
                if (typeof this.$refs.providers !== 'undefined') {
                    this.$refs.providers.mediaSearch();
                }
            },

            loadMediaProviders() {
                if (this.loading) {
                    return;
                }

                this.loading = true;
                this.axios   = this.$axiosToken.source();

                this.$axios.get(
                    this.route('pwatcher.wishlist.request-media-providers'),
                    {
                        cancelToken: this.axios.token,
                    },
                ).then((response) => {
                    this.mediaProviders = response.data;
                    this.$logger.debug('Media Providers', {providers: response.data}, 'media-search');

                    this.$emit('media-search:providers-loaded', response.data);
                }).finally(() => {
                    this.loading = false;
                    this.axios   = null;
                });
            },
        },
    };
</script>
