<template>
    <ValidationProvider ref="observer" :name="$attrs.translationLabel || $attrs.label" :rules="rules" v-slot="{ errors, valid }" v-bind="$refs" :vid="$attrs.name">
        <v-dialog
            v-if="$vuetify.breakpoint.smAndDown"
            ref="dialog"
            v-model="menu"
            :width="$vuetify.breakpoint.xs ? '90%' : '290px'"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    :value="displayValue"
                    :label="$attrs.translationLabel || $attrs.label"
                    :prepend-icon="$attrs.prependIcon"
                    :error-messages="hasRules ? errors : []"
                    :success="hasRules && valid"
                    :hint="$attrs.hint"
                    :persistent-hint="$attrs['persistent-hint'] !== false"
                    :id="$attrs.name"
                    v-on:click:clear="innerValue = ''"
                    :clearable="$attrs.clearable !== false"
                    readonly
                    v-on="on"
                >
                    <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
                </v-text-field>
            </template>
            <v-date-picker
                v-model="innerValue"
                first-day-of-week="1"
                locale="de"
                :no-title="noTitle"
                :min="$attrs.min"
                :max="$attrs.max"
                scrollable
                @input="menu = false"
            >
                <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
            </v-date-picker>
        </v-dialog>

        <v-menu
            v-else
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                    :value="displayValue"
                    :label="$attrs.translationLabel || $attrs.label"
                    :prepend-icon="$attrs.prependIcon"
                    :error-messages="hasRules ? errors : []"
                    :success="hasRules && valid"
                    :hint="$attrs.hint"
                    :persistent-hint="$attrs['persistent-hint'] !== false"
                    :id="$attrs.name"
                    v-on:click:clear="innerValue = ''"
                    :clearable="$attrs.clearable !== false"
                    readonly
                    v-on="on"
                >
                    <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
                </v-text-field>
            </template>
            <v-date-picker
                v-model="innerValue"
                first-day-of-week="1"
                locale="de"
                :no-title="noTitle"
                :min="$attrs.min"
                :max="$attrs.max"
                @input="menu = false"
            >
                <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
            </v-date-picker>
        </v-menu>
    </ValidationProvider>
</template>

<script>
    export default {
        props: {
            rules:   {
                type:    [Object, String],
                default: '',
            },
            // must be included in props
            value:   {
                type: null,
            },
            noTitle: {
                type:    Boolean,
                default: false,
            },
        },
        data:  () => ({
            innerValue:   '',
            displayValue: '',
            menu:         false,
        }),
        watch: {
            // Handles internal model changes.
            innerValue(newVal) {
                this.$emit('input', newVal);
                this.setDisplayDate();
            },
            // Handles external model changes.
            value(newVal) {
                this.innerValue = newVal;
            },
        },

        computed: {
            hasRules() {
                if (! this.rules) {
                    return false;
                }

                if (typeof this.rules === 'string' && this.rules === '') {
                    return false;
                }

                if (typeof this.rules !== 'object' || Array.isArray(this.rules)) {
                    return false;
                }

                return Object.keys(this.rules).length >= 1;
            }
        },

        methods: {
            setDisplayDate() {
                if (! this.innerValue || this.innerValue === '') {
                    this.displayValue = '';
                } else {
                    this.displayValue = this.$date(this.innerValue).format(this.$t('dates.date'));
                }
            },
        },

        created() {
            if (this.value) {
                this.innerValue = this.value;
            }
        },
    };
</script>
