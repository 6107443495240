<template>
    <div>
        <v-row :class="{'pl-4': ! hasPoster}">
            <v-col cols="4" v-if="hasApi">
                {{$t('Media.detailCard.additionals.api')}}<br/>{{api}}
            </v-col>

            <v-col cols="4" v-if="hasType">
                {{$t('Media.detailCard.additionals.type')}}<br/>{{type}}
            </v-col>

            <v-col cols="4" v-if="hasSubType">
                {{$t('Media.detailCard.additionals.subType')}}<br/>{{subType}}
            </v-col>

            <v-col cols="4" v-if="releaseDate">
                {{$t('Media.detailCard.additionals.release')}}<br/>{{releaseDate}}
            </v-col>

            <v-col cols="4" v-if="seasons && seasons !== '' && seasons !== 0">
                {{$t('Media.detailCard.additionals.seasons')}}<br/>{{seasons}}
            </v-col>

            <v-col cols="4" v-if="runtime && runtime !== '' && runtime !== 0">
                {{$t('Media.detailCard.additionals.runtime')}}<br/>{{runtime}}
            </v-col>

            <v-col cols="4" v-if="keywordsText">
                {{$t('Media.detailCard.additionals.keywords')}}<br/>{{keywordsText}}
            </v-col>

            <v-col cols="4" v-if="synonymText">
                {{$t('Media.detailCard.additionals.synonyms')}}<br/>{{synonymText}}
            </v-col>

            <v-col cols="4" v-if="alternativeTitlesText">
                {{$t('Media.detailCard.additionals.alternatives')}}<br/>{{alternativeTitlesText}}
            </v-col>

        </v-row>

        <div class="mt-2 subtitle-1" v-if="tagline && tagline !== ''">
            {{tagline}}
        </div>
    </div>
</template>

<script>
    export default {
        computed: {
            keywordsText() {
                if (! this.keywords || ! Array.isArray(this.keywords) || this.keywords.length <= 0) {
                    return null;
                }

                return this.keywords.join(', ');
            },

            alternativeTitlesText() {
                if (! this.alternativeTitles || ! Array.isArray(this.alternativeTitles) || this.alternativeTitles.length <= 0) {
                    return null;
                }

                return this.alternativeTitles.join(', ');
            },

            synonymText() {
                if (! this.synonyms || ! Array.isArray(this.synonyms) || this.synonyms.length <= 0) {
                    return null;
                }

                return this.synonyms.join(', ');
            },

            releaseDate() {
                if (! this.release || this.release === '') {
                    return null;
                }

                try {
                    return this.$date(this.release).format(this.$t('dates.date'));
                } catch (e) {}

                return null;
            },

            hasApi() {
                return this.api && this.api !== '';
            },

            hasType() {
                return this.type && this.type !== '';
            },

            hasSubType() {
                return this.subType && this.subType !== '';
            },
        },

        props: {
            hasPoster: {
                type: Boolean,
                default: true
            },

            alternativeTitles: {
                type: Array,
                default: null
            },

            synonyms: {
                type:    Array,
                default: null,
            },

            keywords: {
                type:    Array,
                default: null,
            },

            api: {
                type:    String,
                default: null,
            },

            type: {
                type:    String,
                default: null,
            },

            runtime: {
                type: [Number, String],
                default: null,
            },

            seasons: {
                type: [Number, String],
                default: null,
            },

            tagline: {
                type:    String,
                default: null,
            },

            release: {
                type:    String,
                default: null,
            },

            subType: {
                type:    String,
                default: null,
            },
        },
    };
</script>
