<template>
    <div>
        <v-card-text class=" w-full mt-0 pt-0 self-start white--text">
            <v-flex class="white--text" v-if="(leftSubText && leftSubText !== '') || (rightSubText && rightSubText !== '')">
                <div v-html="leftSubText"/>
                <v-spacer/>
                <div v-html="rightSubText"/>
            </v-flex>
            <v-tooltip top :disabled="hideSubTextTooltip">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <small>{{smallDebugText}}</small>
                        <span v-html="usedSubText"/>
                    </div>
                </template>
                <span v-html="subText" />
            </v-tooltip>
            <v-tooltip top :disabled="hideSecondSubTextTooltip">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <span v-html="usedSecondSubText"/>
                    </div>
                </template>
                <span v-html="secondSubText" />
            </v-tooltip>
            <v-tooltip top :disabled="hideThirdSubTextTooltip">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <span v-html="usedThirdSubText"/>
                    </div>
                </template>
                <span v-html="thirdSubText" />
            </v-tooltip>
        </v-card-text>
        <v-card-text class="w-full mt-0 pt-0 self-end">
            <v-tooltip v-if="desc !== ''" top :disabled="hideDescriptionTooltip">
                <template v-slot:activator="{ on }">
                    <div v-on="on" v-html="usedDesc"/>
                </template>
                <span v-html="desc"/>
            </v-tooltip>
        </v-card-text>
    </div>
</template>

<script>
    export default {
        props: {
            hasPoster: {
                type: Boolean,
                default: true
            },

            allowTooltips: {
                type:    Boolean,
                default: true,
            },

            desc: {
                type:    String,
                default: null,
            },

            smallDesc: {
                type:    String,
                default: null,
            },

            leftSubText: {
                type:    String,
                default: null,
            },

            rightSubText: {
                type:    String,
                default: null,
            },

            smallDebugText: {
                default: null,
            },

            subText: {
                type:    String,
                default: null,
            },

            smallSubText: {
                type:    String,
                default: null,
            },

            secondSubText: {
                type:    String,
                default: null,
            },

            smallSecondSubText: {
                type:    String,
                default: null,
            },

            thirdSubText: {
                type:    String,
                default: null,
            },

            smallThirdSubText: {
                type:    String,
                default: null,
            },
        },

        computed: {
            usedSecondSubText() {
                if (this.smallSecondSubText && this.smallSecondSubText !== '') {
                    return this.smallSecondSubText;
                }

                return this.secondSubText;
            },

            usedThirdSubText() {
                if (this.smallThirdSubText && this.smallThirdSubText !== '') {
                    return this.smallThirdSubText;
                }

                return this.thirdSubText;
            },

            usedSubText() {
                if (this.smallSubText && this.smallSubText !== '') {
                    return this.smallSubText;
                }

                return this.subText;
            },

            hideTooltips() {
                return this.allowTooltips !== false && this.isMobileOrTablet();
            },

            hideSubTextTooltip() {
                if (this.hideTooltips === true) {
                    return true;
                }

                return this.usedSubText === this.subText;
            },

            hideSecondSubTextTooltip() {
                if (this.hideTooltips === true) {
                    return true;
                }

                return this.usedSecondSubText === this.secondSubText;
            },

            hideThirdSubTextTooltip() {
                if (this.hideTooltips === true) {
                    return true;
                }

                return this.usedThirdSubText === this.thirdSubText;
            },

            hideDescriptionTooltip() {
                if (this.hideTooltips === true || this.smallDesc === '' || this.smallDesc === null) {
                    return true;
                }

                return this.smallDesc === this.desc;
            },

            usedDesc() {
                if (this.smallDesc && this.smallDesc !== '') {
                    return this.smallDesc;
                }

                return this.desc;
            },
        },
    };
</script>
