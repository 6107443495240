<template>
    <div>
        <v-row justify="center">
            <v-col
                cols="12"
                md="11"
                :style="style"
                class="flex items-stretch w-full"
            >
                <media-detail-card
                    :poster="item.poster"
                    :background="item.background"
                    :banner="item.banner"
                    :title="item.name"
                    :cast="cast"
                    :companies="companies"
                    :crew="crew"
                    :alternative-titles="item.details ? item.details.alternativeTitles : null"
                    :synonyms="item.details ? item.details.aliases : null"
                    :keywords="item.details ? item.details.keywords : null"
                    :release="item.release"
                    :runtime="item.details ? item.details.runtime : null"
                    :seasons="item.details ? item.details.seasonsCount : null"
                    :tagline="item.details ? item.details.tagline : null"
                    :small-title="item.title"
                    :year="item.year"
                    :desc="item.descriptionStripped"
                    :small-desc="usedDesc"
                    :left-sub-text="item.apiSmallTitle"
                    :right-sub-text="typeAndSubType"
                    :small-debug-text="usedBreakpoint"
                    :sub-text="allGenres"
                    :small-sub-text="genreList"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import MediaDetailCard from '@symfia/library/Views/Cards/Media/MediaDetailCard';

    export default {
        components: {
            MediaDetailCard,
        },

        props: {
            name: {
                type:     String,
                required: true,
                default:  null,
            },

            year: {
                type:     [String, Number],
                required: true,
                default:  null,
            },

            item: {
                type:     Object,
                required: true,
            },
        },

        data: () => ({
            data: false,
        }),

        methods: {},

        computed: {
            style() {
                switch (this.$breakpoint.name) {
                    case 'xs':
                        return 'max-height: 450px; min-height:450px;';

                    case 'sm':
                        return 'max-height: 375px; min-height:300px;';

                    default:
                        return 'max-height: 375px; min-height:375px;';
                }
            },

            allGenres() {
                if (typeof this.item.details === 'undefined' || ! this.item.details || typeof this.item.details.genres === 'undefined' || ! Array.isArray(this.item.details.genres)) {
                    return '';
                }

                return this.item.details.genres.join(', ');
            },

            genreList() {
                if (typeof this.item.details === 'undefined' || ! this.item.details || typeof this.item.details.genres === 'undefined' || ! Array.isArray(this.item.details.genres)) {
                    return '';
                }

                let genres = this.item.details.genres;

                const slice = this.usedSliceForGenre;

                if (slice) {
                    genres = genres.slice(0, slice);
                }

                return genres.join(', ');
            },

            companies() {
                if (typeof this.item.details === 'undefined' || ! this.item.details || typeof this.item.details.companies === 'undefined' || ! Array.isArray(this.item.details.companies)) {
                    return null;
                }

                let companies = this.item.details.companies;

                const slice = this.usedSliceForCastAndCrew;

                if (slice) {
                    companies = companies.slice(0, slice);
                }

                return companies;
            },

            cast() {
                if (typeof this.item.details === 'undefined' || ! this.item.details || typeof this.item.details.cast === 'undefined' || ! Array.isArray(this.item.details.cast)) {
                    return null;
                }

                let cast = this.item.details.cast;

                const slice = this.usedSliceForCastAndCrew;

                if (slice) {
                    cast = cast.slice(0, slice);
                }

                return cast;
            },

            crew() {
                if (typeof this.item.details === 'undefined' || ! this.item.details) {
                    return null;
                }

                let crew             = [];
                let crewMembers      = [];
                let createdByMembers = [];

                if (typeof this.item.details.crew !== 'undefined' && Array.isArray(this.item.details.crew)) {
                    crewMembers = this.item.details.crew;
                }

                if (typeof this.item.details.createdBy !== 'undefined' && Array.isArray(this.item.details.createdBy)) {
                    createdByMembers = this.item.details.createdBy;
                }

                crew = crew.concat(
                    createdByMembers,
                    crewMembers,
                );

                if (crew.length < 1) {
                    return [];
                }

                const slice = this.usedSliceForCastAndCrew;

                if (slice) {
                    crew = crew.slice(0, slice);
                }

                return crew;
            },

            usedBreakpoint() {
                if (this.isDebugEnabled() === true && this.isLocalEnv() === true) {
                    if (! this.hasPoster) {
                        return 1;
                    }

                    if (this.isSmartphonePortrait()) {
                        return 2;
                    }

                    if (this.isSmartphoneLandscape()) {
                        return 3;
                    }

                    if (this.isSmallLaptopScreen()) {
                        return 4;
                    }

                    if (! this.isLargeLaptopScreen()) {
                        return 5;
                    }

                    if (this.is4kResolution()) {
                        return 6;
                    }

                    if (this.isXlBreakpoint()) {
                        return 7;
                    }

                    return 8;
                }
            },

            usedSliceForGenre() {
                if (! this.hasPoster) {

                }

                if (this.isSmartphonePortrait()) {
                    return 3;
                }

                if (this.isSmartphoneLandscape()) {
                    return 5;
                }

                if (this.isSmallLaptopScreen()) {

                }

                if (! this.isLargeLaptopScreen()) {

                }

                if (this.is4kResolution()) {

                }

                if (this.isXlBreakpoint()) {

                }

                return null;
            },

            usedSliceForCastAndCrew() {
                if (! this.hasPoster) {

                }

                if (this.isSmartphonePortrait()) {
                    return 4;
                }

                if (this.isSmartphoneLandscape()) {
                    return 6;
                }

                if (this.isSmallLaptopScreen()) {
                    return 6;
                }

                if (! this.isLargeLaptopScreen()) {

                }

                if (this.is4kResolution()) {
                    return 10;
                }

                if (this.isXlBreakpoint()) {

                }

                return 8;
            },

            usedDesc() {
                if (! this.hasPoster) {
                    return this.item.descriptionStripped;
                }

                if (this.isSmartphonePortrait()) {
                    return this.item.descriptionTruncatedStripped200;
                }

                if (this.isSmartphoneLandscape()) {
                    return this.item.descriptionTruncatedStripped800;
                }

                if (this.isSmallLaptopScreen()) {
                    return this.item.descriptionTruncatedStripped850;
                }

                if (! this.isLargeLaptopScreen()) {
                    return this.item.descriptionTruncatedStripped900;
                }

                if (this.is4kResolution()) {
                    return this.item.descriptionTruncatedStripped1200;
                }

                if (this.isXlBreakpoint()) {
                    return this.item.descriptionTruncatedStripped1100;
                }

                return this.item.descriptionTruncatedStripped900;
            },

            usedTitle() {
                return this.item.name;
            },

            hasPoster() {
                return this.item.poster && this.item.poster !== '';
            },

            typeAndSubType() {
                let type = this.item.typeTitle;

                if (this.item.subTypeTitle && this.item.subTypeTitle !== '') {
                    if (type !== '') {
                        type += ' / ';
                    }

                    type += this.item.subTypeTitle;
                }

                return type;
            },
        },
    };
</script>
