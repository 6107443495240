<template>
    <v-row :class="{'pl-4': ! hasPoster}">
        <v-col
            cols="6"
            sm="4"
            md="4"
            lg="3"
            xl="2"
            v-for="company in companies"
            :key="company.name"
        >
            <v-tooltip
                v-if="company.poster && company.poster !== ''"
                left
                allow-overflow
                open-delay="300"
                :disabled="isMobile"
            >
                <template v-slot:activator="{ on }">
                    <v-avatar
                        v-on="on"
                        :size="72"
                        color="white"
                    >
                        <v-img :src="company.poster" position="0% 35%"/>
                    </v-avatar>

                    <br/>
                </template>
                <span>
                    <v-img :src="company.poster" position="top" max-width="200px"/>
                </span>
            </v-tooltip>
            <div>
                {{company.name}}
            </div>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            hasPoster: {
                type: Boolean,
                default: true
            },

            companies: {
                type:    Array,
                default: null,
            },
        },

        methods: {

        },

        computed: {
            isMobile() {
                return this.isMobileOrTablet();
            },
        },
    };
</script>
