<template>
    <div>
        <v-row>
            <v-col cols="12" sm="6">
                <switch-with-validation
                    v-model="innerHd"
                    :label="$t('Wishlist.create.form.hd')"
                    :hint="$t('Wishlist.create.form.hdHint')"
                    :persistent-hint="$vuetify.breakpoint.mdAndUp"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <switch-with-validation
                    v-model="innerOv"
                    :label="$t('Wishlist.create.form.ov')"
                    :hint="$t('Wishlist.create.form.ovHint')"
                    :persistent-hint="$vuetify.breakpoint.mdAndUp"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <switch-with-validation
                    v-model="innerPriority"
                    :label="$t('Wishlist.create.form.priority')"
                    :hint="$t('Wishlist.create.form.priorityHint')"
                    :persistent-hint="$vuetify.breakpoint.mdAndUp"
                />
            </v-col>

            <v-col cols="12" sm="6">
                <radio-group-with-validation
                    v-model="innerType"
                    :row="$vuetify.breakpoint.mdAndUp"
                    :label="$t('Wishlist.create.form.type')"
                    :hint="$t('Wishlist.create.form.typeHint')"
                    :persistent-hint="$vuetify.breakpoint.mdAndUp"
                    :entries="[
                        {
                            label: $t('Wishlist.create.form.types.wish'),
                            value: 'wish'
                        },
                        {
                            label: $t('Wishlist.create.form.types.upgrade'),
                            value: 'upgrade'
                        },
                        {
                            label: $t('Wishlist.create.form.types.error'),
                            value: 'error'
                        }
                    ]"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import RadioGroupWithValidation from '@symfia/core/components/VeeValidate/RadioGroupWithValidation';
    import SwitchWithValidation from '@symfia/core/components/VeeValidate/SwitchWithValidation';

    export default {
        components: {
            RadioGroupWithValidation,
            SwitchWithValidation
        },

        props: {
            ov: {
                type:     Boolean,
                required: true,
            },

            type: {
                type:     String,
                required: true,
            },

            hd: {
                type:     Boolean,
                required: true,
            },

            priority: {
                type:     Boolean,
                required: true,
            },
        },

        watch: {
            hd: function (newHd, oldValue) {
                if (newHd !== oldValue) {
                    this.innerHd = newHd;
                }
            },

            innerHd: function (newHd) {
                this.$emit('wishlist-field-change:hd', newHd);
            },

            ov: function (newOv, oldValue) {
                if (newOv !== oldValue) {
                    this.innerOv = newOv;
                }
            },

            innerOv: function (newOv) {
                this.$emit('wishlist-field-change:ov', newOv);
            },

            type: function (newType, oldValue) {
                if (newType !== oldValue) {
                    this.innerType = newType;
                }
            },

            innerType: function (newType) {
                this.$emit('wishlist-field-change:type', newType);
            },

            priority: function (newPriority, oldValue) {
                if (newPriority !== oldValue) {
                    this.innerPriority = newPriority;
                }
            },

            innerPriority: function (newPriority) {
                this.$emit('wishlist-field-change:priority', newPriority);
            },
        },

        mounted() {

        },

        data: () => ({
            innerHd:       false,
            innerOv:       false,
            innerPriority: false,
            innerType:     '',
            menu:          false,
        }),

        methods: {},

        created() {
            this.innerType     = this.type;
            this.innerHd       = this.hd;
            this.innerOv       = this.ov;
            this.innerPriority = this.priority;
        },

        computed: {},
    };
</script>
