<template>
    <v-row :class="{'pl-4': ! hasPoster}">
        <v-col
            cols="6"
            sm="4"
            md="4"
            lg="3"
            xl="2"
            v-for="castMember in cast"
            :key="castMember.name +'-' + castMember.character"
        >
            <v-tooltip
                v-if="castMember.poster && castMember.poster !== ''"
                left
                allow-overflow
                open-delay="300"
                :disabled="isMobile"
            >
                <template v-slot:activator="{ on }">
                    <v-avatar
                        v-on="on"
                        :size="72"
                    >
                        <v-img :src="castMember.poster" position="0% 35%"/>
                    </v-avatar>

                    <br />
                </template>
                <span>
                    <v-img :src="castMember.poster" position="top" max-width="200px"/>
                </span>
            </v-tooltip>

            <v-tooltip top :disabled="isMobile || ! getAlternativeNames(castMember)">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        {{castMember.character}}<br>
                        {{castMember.name}}
                    </div>
                </template>
                <span>{{ getAlternativeNames(castMember) }}</span>
            </v-tooltip>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        props: {
            hasPoster: {
                type: Boolean,
                default: true
            },

            cast: {
                type:    Array,
                default: null,
            },
        },

        methods: {
            getAlternativeNames(castMember) {
                if (! castMember.alternativeNames || ! Array.isArray(castMember.alternativeNames) || castMember.alternativeNames.length < 1) {
                    return null;
                }

                return castMember.alternativeNames.join(', ');
            },
        },

        computed: {
            isMobile() {
                return this.isMobileOrTablet()
            }
        }
    };
</script>
