<template>
    <div class="transparent-tabs">
        <v-tabs v-if="hasMultipleProviders" show-arrows v-model="tab">
            <v-tab
                v-for="(providerData, provider) in providers"
                :key="'tab-'+provider"
                v-if="dataForProviderExist(provider)"
                class="feature-flag-tab"
            >
                {{ providerData.title }}
            </v-tab>

            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="(providerData, provider) in providers"
                    :key="'tab-items-'+provider"
                    v-if="dataForProviderExist(provider)"
                >
                    <v-card flat>
                        <v-card-text>
                            <media-search-results-grouped-by-type
                                v-if="groupedByType"
                                :types="types"
                                :media="getMediaForProvider(provider)"
                                v-on:media-search:result-selected="selectMedia"
                                :name="name"
                                :year="year"
                                :all-results-length="allResultsLength"
                                :force-large="forceLarge"
                            />
                            <media-search-results-list
                                v-else
                                :media="getMediaForProvider(provider)"
                                v-on:media-search:result-selected="selectMedia"
                                :name="name"
                                :year="year"
                                :all-results-length="allResultsLength"
                                :force-large="forceLarge"
                            />
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-tabs>
        <template v-else>
            <media-search-results-grouped-by-type
                v-if="groupedByType"
                v-on:media-search:result-selected="selectMedia"
                :types="types"
                :media="media"
                :name="name"
                :year="year"
                :all-results-length="allResultsLength"
                :force-large="forceLarge"
            />
            <media-search-results-list
                v-else
                v-on:media-search:result-selected="selectMedia"
                :media="media"
                :name="name"
                :year="year"
                :all-results-length="allResultsLength"
                :force-large="forceLarge"
            />
        </template>
    </div>
</template>

<script>
    import MediaSearchResultsList from '@symfia/media/views/MediaSearch/Results/MediaSearchResultsList';
    import MediaSearchResultsGroupedByType from '@symfia/media/views/MediaSearch/Results/MediaSearchResultsGroupedByType';

    export default {
        components: {
            MediaSearchResultsList,
            MediaSearchResultsGroupedByType,
        },

        props: {
            media: {
                type:     Array,
                required: true,
            },

            providers: {
                type:     Object,
                required: true,
            },

            types: {
                type:     Object,
                required: true,
            },

            groupedByType: {
                type:    Boolean,
                default: false,
            },

            allResultsLength: {
                type:    Number,
                default: null,
            },

            name: {
                type:    String,
                default: null,
            },

            year: {
                type:    String,
                default: null,
            },

            forceLarge: {
                type:    Boolean,
                default: false,
            },
        },

        data: () => ({
            tab: null,
        }),

        methods: {
            selectMedia(selected) {
                this.$emit('media-search:result-selected', selected);
            },

            getMediaForProvider(provider) {
                return this.media.filter((result) => {
                    return result.api === provider;
                });
            },

            dataForProviderExist(provider) {
                const media = this.getMediaForProvider(provider);

                return media.length > 0;
            },
        },

        computed: {
            hasMultipleProviders() {
                return Object.keys(this.providers).length > 1;
            },
        },
    };
</script>
