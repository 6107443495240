var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticClass:" w-full mt-0 pt-0 self-start white--text"},[((_vm.leftSubText && _vm.leftSubText !== '') || (_vm.rightSubText && _vm.rightSubText !== ''))?_c('v-flex',{staticClass:"white--text"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.leftSubText)}}),_c('v-spacer'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.rightSubText)}})],1):_vm._e(),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.hideSubTextTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('small',[_vm._v(_vm._s(_vm.smallDebugText))]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.usedSubText)}})])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.subText)}})]),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.hideSecondSubTextTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',{domProps:{"innerHTML":_vm._s(_vm.usedSecondSubText)}})])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.secondSubText)}})]),_c('v-tooltip',{attrs:{"top":"","disabled":_vm.hideThirdSubTextTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',{domProps:{"innerHTML":_vm._s(_vm.usedThirdSubText)}})])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.thirdSubText)}})])],1),_c('v-card-text',{staticClass:"w-full mt-0 pt-0 self-end"},[(_vm.desc !== '')?_c('v-tooltip',{attrs:{"top":"","disabled":_vm.hideDescriptionTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({domProps:{"innerHTML":_vm._s(_vm.usedDesc)}},on))]}}],null,false,2980568625)},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.desc)}})]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }