<template>
    <v-card flat class="position-relative w-full flex v-card-without-transparent">
        <v-img
            :src="backgroundImage"
            v-if="backgroundImage !== null"
            @load="backgroundLoaded = true"
            :position="position"
        >
            <div class="bg-black-opacity-60 w-full h-full">
                <media-card-content
                    :small-card="smallCard"
                    :allow-tooltips="allowTooltips"
                    :title="title"
                    :small-title="smallTitle"
                    :api="api"
                    :keywords="keywords"
                    :release="release"
                    :runtime="runtime"
                    :cast="cast"
                    :companies="companies"
                    :crew="crew"
                    :seasons="seasons"
                    :tagline="tagline"
                    :alternative-titles="alternativeTitles"
                    :synonyms="synonyms"
                    :sub-type="subType"
                    :type="type"
                    :year="year"
                    :desc="desc"
                    :small-desc="smallDesc"
                    :left-sub-text="leftSubText"
                    :right-sub-text="rightSubText"
                    :small-debug-text="smallDebugText"
                    :sub-text="subText"
                    :small-sub-text="smallSubText"
                    :second-sub-text="secondSubText"
                    :third-sub-text="thirdSubText"
                    :small-third-sub-text="smallThirdSubText"
                    :small-second-sub-text="smallSecondSubText"
                    :poster="posterForContent"
                >
                    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                </media-card-content>
            </div>
        </v-img>
        <div
            class="flex"
            v-else>
            <media-card-content
                :small-card="smallCard"
                :allow-tooltips="allowTooltips"
                :api="api"
                :keywords="keywords"
                :release="release"
                :runtime="runtime"
                :seasons="seasons"
                :tagline="tagline"
                :cast="cast"
                :crew="crew"
                :companies="companies"
                :alternative-titles="alternativeTitles"
                :synonyms="synonyms"
                :sub-type="subType"
                :type="type"
                :title="title"
                :small-title="smallTitle"
                :year="year"
                :desc="desc"
                :small-desc="smallDesc"
                :left-sub-text="leftSubText"
                :right-sub-text="rightSubText"
                :small-debug-text="smallDebugText"
                :sub-text="subText"
                :small-sub-text="smallSubText"
                :second-sub-text="secondSubText"
                :small-second-sub-text="smallSecondSubText"
                :third-sub-text="thirdSubText"
                :small-third-sub-text="smallThirdSubText"
                :poster="posterForContent"
            >
                <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
            </media-card-content>
        </div>
    </v-card>
</template>

<script>
    import MediaCardContent from './Helper/MediaDetailCardContent';

    export default {
        components: {
            MediaCardContent,
        },

        props: {
            smallCard: {
                type:    Boolean,
                default: false,
            },

            companies: {
                type: Array,
                default: null
            },

            cast: {
                type: Array,
                default: null
            },

            crew: {
                type: Array,
                default: null
            },

            alternativeTitles: {
                type: Array,
                default: null
            },

            synonyms: {
                type:    Array,
                default: null,
            },

            keywords: {
                type:    Array,
                default: null,
            },

            api: {
                type:    String,
                default: null,
            },

            type: {
                type:    String,
                default: null,
            },

            runtime: {
                type: [Number, String],
                default: null,
            },

            seasons: {
                type: [Number, String],
                default: null,
            },

            tagline: {
                type:    String,
                default: null,
            },

            release: {
                type:    String,
                default: null,
            },

            subType: {
                type:    String,
                default: null,
            },

            allowTooltips: {
                type:    Boolean,
                default: true,
            },

            background: {
                type:    String,
                default: null,
            },

            banner: {
                type:    String,
                default: null,
            },

            title: {
                type:    String,
                default: null,
            },

            smallTitle: {
                type:    String,
                default: null,
            },

            year: {
                type:    [Number, String],
                default: null,
            },

            desc: {
                type:    String,
                default: null,
            },

            smallDesc: {
                type:    String,
                default: null,
            },

            leftSubText: {
                type:    String,
                default: null,
            },

            rightSubText: {
                type:    String,
                default: null,
            },

            smallDebugText: {
                default: null,
            },

            subText: {
                type:    String,
                default: null,
            },

            smallSubText: {
                type:    String,
                default: null,
            },

            secondSubText: {
                type:    String,
                default: null,
            },

            smallSecondSubText: {
                type:    String,
                default: null,
            },

            thirdSubText: {
                type:    String,
                default: null,
            },

            smallThirdSubText: {
                type:    String,
                default: null,
            },

            poster: {
                type:    String,
                default: null,
            },

            allowPosterAsBackground: {
                type:    Boolean,
                default: true,
            },

            usePosterIfAsBackground: {
                type:    Boolean,
                default: true,
            },

            preferPosterOverBanner: {
                type:    Boolean,
                default: false,
            },
        },

        data: () => ({
            backgroundLoaded: false,
        }),

        methods: {},

        computed: {
            position() {
                if (! this.backgroundLoaded) {
                    return 'center right';
                }

                return 'center center';
            },

            posterForContent() {
                if (this.usePosterIfAsBackground === false && this.poster && this.poster === this.backgroundImage) {
                    return false;
                }

                return this.poster && this.poster !== '' ? this.poster : null;
            },

            backgroundImage() {
                if (this.background && this.background !== '') {
                    return this.background;
                }

                if (this.allowPosterAsBackground
                    && this.preferPosterOverBanner
                    && this.poster
                    && this.poster !== ''
                ) {
                    return this.poster;
                }

                if (this.banner && this.banner !== '') {
                    return this.banner;
                }

                if (this.allowPosterAsBackground
                    && this.poster
                    && this.poster !== ''
                ) {
                    return this.poster;
                }

                return null;
            },
        },
    };
</script>
